.form-section{
    height: auto;
    background: linear-gradient(to bottom, #000 29%, #fff 29%);
   position: relative;
  z-index: 99;
}
.form-section::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -99;
    height: 250px;
    background-color: #000;
    background-image: linear-gradient(to right, #000 , #5d282d);
  }
.form-section .fill-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom:17px;
}
.form-section .text{
    text-align: left;
}
.form-section .css-1aquho2-MuiTabs-indicator {
   display: none;
}

.form-section .para{
    font-size: 17px;
    /* color: #c1bebe; */
    color: #fff;
    font-weight: 300;
    margin-bottom: 3px;
}
.form-section .para1{
    font-size: 13px;
    /* color: #c1bebe; */
    color: #fff;
    font-weight: 300;
    margin: 8px 0px;
}
.form-section .box1{
    border: 1px transparent;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    padding: 0px 0px;
}
.form-section .icon-box{
    display: flex;
    align-items: center;
    gap: 3px;
}
.form-section .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 0px 15px;
}
.form-section .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #000;
}

.form-section .box-border{
    border: 1px solid #ddd;
   padding-bottom: 1rem;
    border-radius: 4px;
    margin-top: -8px;
}
.form-section .circle{
    border-radius: 50%;
    padding: 5px;
    background-color:#f1f2f8;
    color: #4a4a4b;
    font-size: 11px;
    width: 15px;
    height: 13px;
    font-weight: 600;
}
.form-section .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{min-width: 150px;}
.form-section .circle:hover,
.form-section .circle1:hover,
.form-section .circle2:hover {
    background-color: #0b437b;
    color: #afc1d4;
}
.form-section .p{
    font-size: 12px;
    font-weight: 600;
    font-family: var(--font-family);
    text-transform:none!important;
}
.form-section .icon-box:active{
    font-weight: 600;
}
.form-section .circle1{
border-radius: 50%;
padding: 5px;
background-color:#f1f2f8;
color: #4a4a4b;
font-size: 13px;
}
.form-section .circle2{
    border-radius: 50%;
    padding: 5px;
    background-color:#f1f2f8;
    color: #4a4a4b;
    font-size: 13px;
    }
.form-section .personal-information{
        background-color:#ffff ;
        padding-top: 2px;
        border-radius: 2px
    }
.form-section  .personal-information h2{
    color:#000;
    background-color:#f1f2f8;
    font-size: 13px;
    padding: 10px 10px;
    text-align: left;
    border-left: 2px solid#636fb1;
   
}
.form-section  .traval-information h2{
    color:#000;
    background-color:#f1f2f8;
    font-size: 13px;
    padding: 10px 10px;
    text-align: left;
    border-left: 2px solid#636fb1;
    
}
.form-section .traval-information .p-tag{
    color:#215487;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
    text-align: left;
    font-family: var(--font-family);
    display:flex;
}
.form-section .box-content{
  
    padding: 1rem 2rem;
}
.form-section .errorMsg {
    color: var(--red);
    font-size: 12px;
    margin-top: 2px;
    font-weight: 500;
    text-align: left;
}
.form-section .form-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.form-section .form-group, .date-pick{
    width: 100%;
}
.form-section .form-group .label {
    color:#215487;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
    text-align: left;
    font-family: var(--font-family);
}
.form-section .box-country{
    width: 60%;
    border-radius: 6px;
    padding: 8px 3px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid #c4c4c4;
    margin: 17px 2px;
}

.form-section .imgtag{
    width: 20px;
    height: 20px;
}
.form-section .form-group label .MuiFormLabel-asterisk {
    color: var(--red);
    padding-left: 2px;
}

.form-section em{
    font-style: normal;
    font-size: 12px;
    font-family: var(--font-family);
    font-weight: 400;
    text-align: left;
    color:#dbdbdb;
}
.form-section input{
    font-style: normal;
    font-size: 12px;
    font-family: var(--font-family);
    font-weight: 400;
    text-align: left;
    height: 2em;
}
.form-section .Name{
    height: auto;
    line-height: 1.4375em;
    font-style: normal;
    font-size: 12px;
    font-family: var(--font-family);
    font-weight: 400;
    text-align: left;
}
.form-section .submit{
    display: flex;
    align-items: center;
    justify-content: flex-end;
   
}
.form-section .btn{
background-color:#ce1126;
color: #fff;
font-family: var(--font-family);
font-weight: 600;
text-transform: none;
margin: 15px 0px;
padding: 0.5rem 1rem;
font-size: 12px;
}
.form-section .btn1{
    background-color:#fff;
    color:#000;
    font-family: var(--font-family);
    font-weight: 400;
   margin: 15px 10px 15px 0px;
    padding: 0.5rem 0.5rem;
    font-size: 12px;
    text-transform:none;
    /* border: 1px solid #ddd; */
    
    }
.form-section .multi-select-custom-value {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: var(--font-family);
      font-size: 12px;
}
.form-section .multi{
        width: 60%;
}
.form-section .dropdown-content{
    font-family: var(--font-family);
    font-size: 13px;
    /* display: flex;
    align-items: center; */
      }
.form-section .rmsc multi-select{
    width: 60%!important;
}
.form-section .selected-country {
    white-space: nowrap;
    border: #ddd;
    background-color: #f3f5f8;
    padding: 5px 8px;
    border-radius: 3px;
}
.form-section .close-button{
        border-radius: 50%;
        background-color: #4a4a4b;
        position: relative;
        top: 2px;
        color: #fff;
        height: 15px;
        width: 15px;
        margin-left: 4px;
}
.form-section .x{
        position: relative;
        left: -3px;
        top: -4px;
        font-size: 11px;
}
.form-section .btn:hover{
    color: #fff;
    background-color:#ee5668 ;
}
.rmsc .dropdown-heading{padding:0px 6px !important;}
.form-section .btn .right{
    width: 15px;
    height: 15px;
}
.form-section .next{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
}
.form-section .previous{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: var(--font-family);
    font-size: 14px;
}
.form-section .select-field{
   
    min-height:3.3em;
    text-align: left;
    font-style: normal;
    font-size: 12px;
    font-family: var(--font-family);
    font-weight: 400;
    text-align: left;       
}
.form-section .css-zow5z4-MuiGrid-root>.MuiGrid-item{padding-left: 0 !important;padding-top: 0 !important;}
 .form-section .css-10voxkt-MuiGrid-root
 {min-width: 23% !important;
      max-width: 23% !important; 
     flex-basis:23% !important;
} 
.form-section .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root {
    padding-left: 20px;
    padding-top: 20px;
    gap: 29px;
}
.form-section .e-multi-select-wrapper .e-chips > .e-chipcontent {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
    color: rgba(0, 0, 0, 0.87);
    font-family: var(--font-family);
    font-size: 12px;
}
.form-section .e-multi-select-wrapper .e-chips {
    margin: -3px 8px 5px 0px;
   border-radius: 4px;
    padding: 3px 8px 3px 8px;  
}
.form-section .e-multi-select-wrapper {
     border: 1px solid #c4c4c4;
     min-height: 35px;
     border-radius: 4px;
     width: 60%;
     padding: 5px 32px 5px 10px;
    
}
 .form-section .e-multi-select-wrapper input[type=text] {
    background: none;
    border: 0;
    font-family: inherit;
    font-size: 13px;
    font-weight: normal;
    min-height: 21px;
    outline: none;
    text-indent: 0;
} 
.form-section .africa-icon {
    background-image: url('../../../assets/Img/africa.png'); 
 
  }
  
  .form-section .ethiopia-icon {
    background-image: url('../../../assets/Img/ethiopia.png');
  }
  
  .form-section .uganda-icon {
    background-image: url('../../../assets/Img/uganda.png'); 
  
  }
  
.form-section .date-pick input{
   
    padding: 8.5px 14px;
}
.form-section .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .circle{
    background-color: #0b437b;
    color: #afc1d4;
}
@media (max-width: 576px){
    .form-section .fill-box {
        padding: 5px 15px;
        flex-direction: column;
}
.form-section .box1{
    padding: 5px 15px;
    /* flex-direction: column; */
    border: 1px transparent;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
     gap: 0rem;
     width: 100%; 
    
}
.form-section .btn{
    float: center;
}

.form-section .multi {
    width: 100%;
}
.form-section .css-zow5z4-MuiGrid-root>.MuiGrid-item{padding-left: 0 !important;padding-top: 0 !important;}
 .form-section .css-10voxkt-MuiGrid-root{
 min-width: 100% !important;
      max-width: 100% !important; 
     flex-basis:100% !important;
} 
}