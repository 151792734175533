.account-formSection {
    background: #f9f9f9;
    border-radius: 7px;
    border: 1px solid #f0f0f0;
    padding: 2rem;
    text-align: left;
    position: relative;
    overflow: hidden;
}

.account-formSection .head-h3 {
    color: var(--secondary);
    border-bottom: 2px solid var(--secondary);
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 25px;
    margin-top: 0;
}

.account-formSection .form-group {
    margin-bottom: 15px;
    position: relative;
}

.account-formSection .form-group label {
    color: var(--primary);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
    font-family: var(--font-family);
}

.account-formSection .form-group label .MuiFormLabel-asterisk {
    color: var(--red);
    padding-left: 2px;
}

.account-formSection .form-group input,
.account-formSection  .form-group .MuiSelect-select,
.account-formSection textarea {
    background-color: #fff;
    border-radius: 6px;
    font-size: 14px;
    padding: 9px 15px;
    font-family: var(--font-family);
    font-weight: 600;
    border: 1px solid #e7e7e7;
}

.account-formSection .form-group .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d9d9d9;
}

.account-formSection .btn-group{
    width: 100%;
    text-align: right;
    margin-top: 7px;
}

.account-formSection .btn-group .btn.btn-cancel {
    background: #6f6f6f;
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding: 6px 30px;
    text-transform: capitalize;
    font-family: var(--font-family);
    margin: 0 15px;
}

.account-formSection .btn-group .btn.btn-submit {
    background: var(--secondary);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding: 6px 30px;
    text-transform: capitalize;
    font-family: var(--font-family);
}

.account-formSection .MuiSelect-icon {
    fill: var(--primary);
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
}

@media (max-width: 567px){
    .account-formSection{
        padding: 1rem;
    }
}