.HeaderUI header {
    background: #fff;
    box-shadow: none;
}
.HeaderUI .Email-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    padding: 10px 0px;
}
.HeaderUI .con{
    background-color:#000;
    padding-left:0px;
    padding-right: 0px;
    width: 100%;
}
.HeaderUI .Email-head .para,
.HeaderUI .Email-head .para > P{
    letter-spacing: 0px;
    color: #e3e3e3;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
}
.HeaderUI .Email-head .light{
    border-radius: 10px;
   padding: 2px 5px;
    background-color: #fff;

}
.HeaderUI .Email-head .para .pl-10 {padding-left: 20px;}
.HeaderUI .border-sec .p1{
    border:2px solid var(--dark);
}
.HeaderUI .border-sec .p2{
    border:2px solid var(--red);
}
.HeaderUI .border-sec .p3{
    border:2px solid var(--green);
}
.HeaderUI .middle-menu {
    padding: 10px 0px;
    justify-content: space-between;
   
}
.HeaderUI {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.HeaderUI .title-text {
    display: flex;
    flex-direction: column;
    color: #000;
    text-align: left;
    font-size: 17px;
    font-weight: 700;
    padding-left: 10px;
}
.HeaderUI .title-text span {
    color: var(--secondary);
    font-size: 13px;
    font-weight: 500;
}
.HeaderUI .log{
    width: 55px;
    height: 55px;
}
.HeaderUI .middle-menu .left, .HeaderUI .middle-menu .right {
    display: flex;
    align-items: center;
}
.HeaderUI .middle-menu .right .menu-text, .mob-menu-item, .HeaderUI .middle-menu .right .menu-items button {
    font-size: 14px;
    text-transform: capitalize;
    font-family: var(--font-family);
    color: var(--dark);
    text-decoration: none;
}
.HeaderUI .middle-menu .right .btn.btn-primary {
    /* background: var(--primary); */
    color: #DE6270;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: var(--font-family);   
}
.HeaderUI .middle-menu .right .btn.btn-primary:hover{
    background-color: transparent!important;
}
.HeaderUI .border{
    border:2px solid #DE6270;
    border-radius: 5px;
    margin-left: 20px;
}
.HeaderUI .star{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid;
    background-color:#281800 ;
    position: relative;
    top: 2px;
}
.HeaderUI .user{
    width: 22px;
    height: 22px;
}
.HeaderUI .arrow{
    width: 20px;
    height: 20px;
    position: relative;
    top: 6px;
}
.HeaderUI .middle-menu .right .btn.btn-secondary {
    color: #DE6270;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: var(--font-family);  
}
.HeaderUI .middle-menu .right .btn.btn-secondary:hover{
    background-color: transparent!important;
}
.HeaderUI .middle-menu .right .menu-items button {
    padding: 0 20px;
}
 .HeaderUI .change{
    color:#DE6270;
 }
.HeaderUI .search{
    width: 21px;
    height: 21px;
    position: relative;
    top: 4px;
    padding: 0 20px;
    cursor: pointer;
   
}
.HeaderUI .middle-menu .right .menu-items button .MuiTouchRipple-root{
    display: none;
}
.HeaderUI .middle-menu .right .menu-items button:hover{
    background-color: transparent;
    color: var(--secondary);
}

.HeaderUI .middle-menu .right .menu-items button.active{
    color: var(--secondary);
}

.HeaderUI .middle-menu .right .menu-items button::after {    
    content: "";
    -webkit-transition: -webkit-transform .3s ease-out,border-color .3s ease-out;
    transition: transform .3s ease-out,border-color .3s ease-out;
    position: absolute;
    display: block;    
    width: 70%;
    bottom: -6px;
    left: 0;
    right: 0;
    margin: auto;
    transform: scaleX(0);
    border-top: 2.5px solid #DE6270;
    padding-bottom: inherit;
}

.HeaderUI .middle-menu .right .menu-items button.active::after {
    transform-origin: right;
    transition: transform 0.3s cubic-bezier(0.25,0,0.4,1),border-color 0.35s cubic-bezier(0.52,0.01,0.16,1);
    border-color:#DE6270;
    backface-visibility: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.HeaderUI .middle-menu .right .menu-items .menu-text {
    padding: 0 15px;
}
.hi_name{
    max-width: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.head-my {
    display: flex;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
}

.head-my .user-name{
    color: var(--secondary);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
}

.head-my .user-name svg{
    width: 30px;
    height: 30px;
}

.head-my .right-img{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

.head-my .right-img img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.my-acc-drop ul li{
    font-size: 14px;
    color: var(--dark);
    font-weight: 400;
    border-left: 2.5px solid transparent;
    border-right: 2.5px solid transparent;
    border-radius: 0;
}

.my-acc-drop ul li svg{
    width: 20px;
    height: 20px;
    margin-right: 7px;
    color: #000;
}
.my-acc-drop ul li:hover svg {
    color: var(--primary);
}

.my-acc-drop ul li:hover{
    color: var(--primary);
    border-left-color: var(--primary);
}

.head-notify{
    margin-left: 20px;
    position: relative;
}

.head-notify .notify-icon{
    color: #000;
    font-size: 30px;
    cursor: pointer;
}

.head-notify .notify-count-outer{
    position: absolute;
    background: var(--secondary);
    display: inline-block;
    line-height: 0px;
    border-radius: 50%;
    left: 16px;
    bottom: 55%;
}

.head-notify .notify-count {
    float: left;
    padding-right: 0px;
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    font-size: 12px;
}

.lang-select .globe-icon {
  color: #e3e3e3;
  font-size: 25px;
  cursor: pointer;
}

.lang-dropdown ul{
    min-width: 200px;
    padding: 0;
    background-color: #fff
}

.lang-dropdown img,
.lang-text img{
    height: 10px;
    margin-right: 7px;
}

.lang-dropdown  ul li {
    font-size: 14px;
    color: var(--dark);
    font-weight: 400;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-radius: 0;
    background-color: #fff !important;
}

.lang-dropdown ul li:hover {
    color: var(--primary);
    background-color: #fff;
}

.lang-dropdown ul li.selected {
    color: var(--primary);
    border-left-color: var(--primary);
}

.HeaderUI .search.mobile-search-icon{
    display: none;
}

.lang-text{
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.lang-text .down-arrow{
    font-size: 20px;
    padding-left: 2px;
}

@media (max-width: 991px){
    .HeaderUI .search.mobile-search-icon {
        top: 0;
        padding: 0 2px;
        display: block;
    }
}

@media(max-width:767px) {
    .HeaderUI .Email-head .para {
        font-size: 12px;
        font-weight: 400;
    }

    .head-my {
        margin-left: 0;
    }

    .head-my .user-name{
        display: none;
    }

    .head-my .right-img{
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 576px){
    .HeaderUI .middle-menu .left img{
        height: 60px;
    }

    .head-my .right-img {
        width: 25px;
        height: 25px;
    }

    .head-notify{
        margin-left: 8px;
    }

    .HeaderUI .title-text{
        font-size: 17px;
    }

    .HeaderUI .title-text span{
        font-size: 12px;
    }

    .HeaderUI .middle-menu .right .ToggleIcon{
        padding-top: 16px;
    }

    
}