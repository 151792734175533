.search-page .form-group {
  text-align: left;
  padding: 25px;
  padding-top: 0;
  padding-bottom: 40px;
  width: 31%;
}

.search-page .form-group input {
  padding-left: 0;
}

.search-page .service-page-detail {
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .search-page .form-group {
    width: auto;
    padding: 17px;
    padding-bottom: 25px;
    padding-top: 0;
  }
}
