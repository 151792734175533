.border-box {
  box-sizing: border-box;
}

.contact-page {
  text-align: left;
  font-family: "Noto Sans", sans-serif;
}

.contact-page .viewallList .service-page-detail {
  padding: 35px;
}

.contact-page .left-contact {
  background-color: var(--primary);
  padding: 25px;
  border-radius: 7px;
  color: #fff;
}

.contact-page .left-contact .adr-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.contact-page .left-contact .adr-box:last-child {
  margin-bottom: 0;
}

.contact-page .left-contact .icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.contact-page .left-contact .icon svg {
  font-size: 20px;
}

.contact-page .left-contact .text {
  font-size: 15px;
  line-height: 1.6;
}

.contact-page .left-contact .adr-box.notes {
  background-color: #fff;
  padding: 15px;
  border-radius: 7px;
  color: var(--primary);
}

.contact-page .left-contact .adr-box.notes .text {
  font-size: 13px;
  line-height: normal;
}

.overflow-hidden {
  overflow: hidden;
}

.Captcha {
  display: flex;
  user-select: none;
  align-items: center;
  margin-bottom: 5px;
}

.Captcha span {
  background: var(--secondary);
  color: #fff;
  padding: 2px 18px;
  font-size: 20px;
  line-height: 1.6;
  border-radius: 5px;
  text-decoration: line-through;
  letter-spacing: 1.5px;
  width: 90px;
  text-align: center;
}

.Captcha svg {
  font-size: 20px;
  margin-left: 7px;
  cursor: pointer;
}

@media (max-width: 767px){
  .contact-page .viewallList .service-page-detail{
    padding: 15px;
  }
  
  .contact-page .account-formSection{
    margin-top: 25px;
  }
}
