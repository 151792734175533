.activity-list {
  padding: 15px 0;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  color: var(--gray);
}

.activity-list .top,
.activity-list .bot {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.activity-list .bot{
  flex-wrap: nowrap;
}

.activity-list .top span {
  color: var(--primary);
  font-weight: 500;
}

.activity-list .bot span {
  color: #000;
}

.activity-list .mid {
  color: #000;
  padding: 10px 0;
}

.activity-list .top .date {
  font-size: 13px;
}

.activity-list .bot .status span {
  background-color: var(--primary);
  padding: 5px 13px;
  color: #fff;
  border-radius: 5px;
}

.activity-list .bot .status span.pending {
  background-color: #ffc107;
}

.activity-list .bot .status span.faild {
    background-color: #bd2130;
}

.activity-list .bot .status span.completed {
    background-color: #28a745;
}
.activity-list .bot{
  font-size: 12px;
  letter-spacing: 0.5px;
}

@media (max-width: 567px){
    .activity-list {
        font-size: 14px;
      }
}