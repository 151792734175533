.notify-detail .notify-list {
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
}

.notify-detail .date,
.notify-popup .date {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notify-detail .date .clock-icon,
.notify-popup .date .clock-icon {
  font-size: 12px;
  margin-right: 3px;
}

.notify-detail .desc,
.notify-popup .desc {
  font-size: 14px;
  margin-top: 7px;
  color: #000;
}

.notify-detail .desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notify-popup .head {
  color: var(--secondary);
  font-size: 16px;
  font-weight: 500;
}

.notify-list-page {
  min-height: 230px;
}

.notify-popup .notify-close {
  outline: none;
  padding: 4px 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  border: none;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.notify-popup .notify-close:hover {
  background-color: var(--secondary);
}

.no-data-found{
    text-align: center;
    width: 100%;
    font-size: 15px;
}
.notify-detail{background-color: white;}
.notify-detail .notify-list:first-child{padding-top: 10px;}
.notify-detail .notify-list:last-child{padding-bottom: 15px;}
@media (max-width: 767px) {
  .notify-list-page {
    min-height: 100px;
  }
  .notify-detail .desc{
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
  .account-formSection{padding: 1rem;}
}
