@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.all-service-header {
    background: transparent linear-gradient(98deg, #000000 0%, #1d0002 50%, #420007ed 100%) 0% 0% no-repeat padding-box;
    padding: 20px 0;
    padding-bottom: 100px;
}

.all-service-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all-service-header .left-div .desc {
    text-align: left;
    font: normal normal normal 20px/27px Noto Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 13px;
    display: block;
}
.all-service .btn.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding: 12px 25px;
    text-transform: uppercase;
    font-family: var(--font-family);
    width: 100%;
  }
.all-service-header .left-div h6 {
    text-align: left;
    font: normal normal normal 20px/27px Noto Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 18px;
    margin: 0;
}

.all-service .all-services-view {
    margin-top: 0em !important;
    margin-bottom: 1.5em !important;
}

.all-service .search-page .service-page-detail {
    box-sizing: border-box;
    border-radius: 4px;
}

.all-service .form-group {
    text-align: left;
    padding: 25px;
    /* padding-top: 10px; */
    /* padding-bottom: 40px; */
}

.all-service .form-group input {
    padding-left: 0;
}

.all-service .no-data-found {
    text-align: center;
    width: 100%;
    font-size: 15px;
}

.all-service .grid {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
}

.all-service .passportPage .grid-text {
    display: flex;
    text-align: center;
    margin-top: 10px;
    color: #323BB4 !important;
    padding: 0 10px 0 10px ;
    font-family: 'Inter', sans-serif !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 20.57px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
}
    
.tab-head {
    display: flex;
    align-items: center;
    margin: 30px;
    border: 1px solid #dedede;
    border-radius: 40px;
    background-color: #f7f7fc;
}

.tab-head .ttl {
    width: 33.33%;
    color: #808080;
    padding: 15px 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 15px;
}

.tab-head .ttl.active {
    color: var(--primary);
}

.all-service .form-control{
    padding: 0 0px 0 10px;
}
.all-service .siblingService .MuiBox-root{
    padding: 8px 0px;
}
.all-service .passportPage .list-item-text{
font-family: 'Inter', sans-serif !important;
/* color: #3d3d3d; */
/* font-weight: 400; */
line-height: 26px;
text-align: left;
margin-bottom: 5px;
letter-spacing: 0.2px;
    
}
.all-service .list-item-arrow{
    padding-left: 11px;
    width: 15px;
    height: 15px;
} 
.all-service .all-service-header-inner .left-div .left-serviceall-text1{
    font-family: 'Inter', sans-serif !important;
    font-weight: 500;
}
.all-service .all-service-header-inner .left-div .left-serviceall-text2{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
}


@media (max-width: 768px) {
    .all-service .form-group {
        width: auto;
        padding: 17px;
        padding-bottom: 25px;
        padding-top: 22px;
    }
    .all-service .grid-text {
        font-size: 15px;
    }
    .all-service .MuiGrid-root>.MuiGrid-item {
        padding-top: 10px !important;
    }
    .all-service .list-item-text {
        font-size: 14px;
    }
    .all-service .MuiTablePagination-root .MuiInputBase-root {
        padding: 10px 0 5px 10px !important;
        width: 5% !important;
    }
}

/* Service Detail Css */
.all-services-view .all-service-details-view{
    font-family: 'Inter', sans-serif !important;
}
.left-div.service-detail-left-div{
    padding-top: 5px;
}
.all-services-view .service-page-detail{
    margin-top: -60px !important;
}

.all-service .container {
    margin-top: 3.5% !important;
    margin-bottom: 2% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.all-service .info-containers {
    margin-top: 4% !important;
    display: flex ;
    text-align: start !important;
    margin-top: -8px !important;
}

.all-service.applyPass .info-name-head {
    font-size: 17.5px;
    text-align: start;
    color: #272727;
    font-weight: 400;
    margin-top: -8px;
}
.all-service .info-name-head1 {
    font-size: 14.5px;
    text-align: start !important;
    color: #000000;
    font-weight: 400;
}

.all-service.applyPass .info-name {
    font-size: 18px;
    font-weight: 600 !important;
    text-align: start !important;
    color: #272727;
}
.passPortAmount .info-amount{
    font-size: 18px;
    font-weight: 600 !important;
    text-align: start !important;
    color: #323bb4;
}

.all-service .info-item {
    display: flex;
    align-items: center;
    margin-top: 1%;
}

.all-service.applyPass .service-name {
    font-family: 'Inter', sans-serif !important;
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 12px !important;
    text-align: start;
    color: #000000 !important;
}

.all-service .label {
    font-size: 15px !important;
    color: #323BB4 !important;
    margin-bottom: 8px !important;
    margin-top: 20px !important;
    text-align: start;
    font-weight: 600;
}
.all-service.detail .label{
    font-size: 15px !important;
    font-weight: 500;
}
.all-service .description {
    font-size: 13.5px;
    font-weight: 400;
    color: #000;
    text-align: start;
    white-space: pre-line;
    line-height: 20px;
    margin-bottom: 2rem;
}

.all-service .payment-containers {
    display: flex;
    text-align: start !important;
}

.all-service .payment-txt1 {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-left: -1;
}
.all-service.detail .bank-group label {
    font-size: 15px;
    color: var(--primary);
    font-weight: 500;
    margin-bottom: 5px;
    width: 45%;
    font-family: 'Inter';
}
.all-service .paymentservice-option {
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    width: 46%;
}

.all-service .paymentOption label {
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 1%;
}
.paymentOption .Radio-btn-grp{
    font-size: 13px !important;
    font-weight: 400 !important;
    margin-top: -7px ;
    margin-bottom: 7px;
}

.all-service .bank-pay-txt1 {
    color: #1E1E2D !important;
    font-size: 13px !important;
    text-align: start;
    font-weight: 400;
    line-height: 1.4375em;
    font-family: 'Inter';
    letter-spacing: 0.00938em;
}

.all-service .bank-payment1 {
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    background-color: #F5F5F5;
    width: 50%;
    padding: 18px 15px;
}
.all-service.detail .bank-payment {
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    background-color: #F5F5F5;
    width: 50%;
    padding: 18px 15px;
    text-align: left;
}
.all-service.detail .row-center.paymentOptionDetails {
    margin-top: 4%;
}
.all-service.detail .bank-payment .paymentOptionDetails .MuiFormGroup-root {
    margin-bottom: 3px;
}
.all-service.detail .bank-group {
    flex-direction: row !important;
}
.all-service .bank-group1 {
    flex-direction: column !important;
    text-align: start;
    display: flex;
    margin-top: 6px;
}

.all-service .bank-head1 {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 600;
    margin-top: 5px;
}

.all-service .bank-detail1 {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 5px;
}

.all-service .payment-btn1 {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
}

.all-service .payment-btn-start1 {
    margin-left: 26%;
    width: 17%;
    padding: 30px 15px;
}

.all-service .pay-btn1 {
    background-color: #323BB4 !important;
    color: #fff;
    /* margin-left: -2px; */
    padding:6px 18px !important;
    font-family: 14px;
    font-weight: 500;
    font-family: 'Inter';
    width: fit-content;
}

.all-service .payment-btn-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
     margin-top: 2%; 
    margin-left: 1%;
    margin-bottom: 2%;
    position: relative;
    left: 26%;
}
.all-service .mbl-con{
    margin: 15px  0px 10px 0px;
}
.all-service.applyPass .img-ministry{
    margin-right: 10px;
    margin-bottom: 10px;
    padding-left:2px ;
    width: 30px;
    height: 30px;
}
.all-service .MuiTablePagination-root .MuiInputBase-root {
    padding: 10px 0 5px; 
    width: 5% !important;
} 
.all-service .icon-item
{
      display: flex;
      align-items: center;
     
  }
  .all-service .min1{
    margin-bottom: 0 !important;
 }
@media (max-width:768px){
    .all-service .icon-item
    {
        display: flex;
        align-items: center;       
    }
    .all-service-header-inner{
        padding-left: 16px;
        padding-right: 16px;
    }
    .all-service.detail .no_wdth_mbl{min-width: 0px !important;}
    .all-service.detail .bank-payment{width: 100%;}
    .all-service .info-item{
        display: flex;
        align-items: center;
        margin-top: 0;
    }
    .all-service .img-ministry{
        width: 22px;
        height: 22px;
    }
    .all-service .info-name {
        font-size: 15px;
    }
    .all-service .info-name-head,
    .all-service .info-name-head1 {
        font-size: 15px;
    } 
    .all-service .description {
        line-height: 25px;
    }
    .all-service .payment-btn-start {
        margin-bottom: 7%;
        left: 0%;
    }
    .all-service .paymentservice-option {
       flex-direction: column;
       width: 100%;
    }
    .all-service .info-containers {
        flex-direction: column;
        margin: 10px 0px 0px 0px;
     }
     .all-service .min1{
        margin-bottom: 0 !important;
     }
     .all-service .paymentOption{
        margin-top: 8px;
     }
     .all-service .paymentOption label {
       margin-left: 0;
    }
}
@media (min-width:769px) and (max-width:1199px){
    .all-service .paymentservice-option {
        width: 75%;
    }
    .all-service .payment-btn-start {
        left: 39%;
    }
}
.cont-step {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
    gap: 5px;
}
.active .tab-title-color{
    color: #323bb4;    
}
.tab-title-color{
    color: #626262;
    font-size: 18px;
}
.cont-step span:nth-child(1){
    font-size: 14px;
    color: #bbbbbb;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.img-step {
    border: 1px solid #626262;
    border-radius: 50%;
    background-color: white;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.tab-head .img-step svg{
    fill: #626262;
    font-size: 25px;
}
.tab-head .active .img-step{
    background-color: #323bb4;
    border: 1px solid transparent;
}
.tab-head .active .img-step svg{
    fill: #fff;
}
.tab-next-btn button{
    background-color: #323bb4;
    font-family: var(--font-family);
    padding: 7px 25px 7px 25px;
}
.arrowIcon-text{
    display: flex;
    align-items: center;
    gap: 5px;
}
.arrowIcon-text svg{
    font-size: 18px;
}
.tab-next-btn{
    justify-content: flex-end;
    margin-right: 60px;
    display: flex;
    margin-bottom: 30px;
}
.gender-opt-radio span{
    width: 25px;
    height: 25px;
    /* background-color: white; */
}
.gender-opt-radio span::before{
    width: 25px;
    height: 25px;
}
.passportPage .mb-0{
    margin-bottom: 0px;
}
 .MuiSelect-select{
    text-align: left;
}
.tab-next-btn{
    /* justify-content: space-between; */
    margin-left: 60px;
}
.nextsvg svg{
    transform: rotate(180deg);
}
.agrreCheck input{
    height: 25px;
    width: 25px;
}
.nopaddForm{
    padding: 0px !important;
}
.agrreCheck{
    display: flex;
    align-items: center;
    gap: 10px;
}
.agrreCheck .checkmark{
    color: #3d3d3d;
}
.hintColor{
    color: #ea1e1e !important;
    font-size: 13px !important;
}
.consenthead{
    justify-content: center;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.consentSec .checkmark{
    font-size: 15px;
}

.passportPage .list-item-text{
    margin-bottom: 5px;
}
.datepcik .MuiInputBase-input{
    height: 0.4rem;
}
.closebtn{
    justify-content: flex-end;
    display: flex;    
    width: 100%;
    margin-left: -23px !important;
}
.uploadBox{
    width: 270px;
    height: 270px;
    background-color: #f7f7f7;
    border: 1px dashed #766f6f;
    border-radius: 0;
}
.uploadBox .uploadTxt{
    font-family: "Inter", sans-serif;
    font-weight: 400;
    margin-top: 5px;
    font-size: 1rem;
}
.uploadGrid{
    padding-top: 20px !important;
}
.passportPage.personal .MuiGrid-item{
    padding-top: 10px;
}
.passportPage.placeorgin .MuiGrid-item{
    padding-top: 5px;
}
.all-service .passportPage.placeorgin .grid-text{
    margin-bottom: 20px;
}
.radioTxt{margin-bottom: 0px;}
.bxRadio{margin-top: 3px;}
.passPortAmount .list-item-text{
    display: flex;
    gap: 6.5rem;
    padding-left: 10px;
}
.info-item.icon-item.passPortAmount {
    margin-bottom: 3.5rem;
}