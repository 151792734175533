.iconBox {
    background-color: #E69F230D;
    padding: 20px 20px 19px 20px;
    border: 0.5px solid #0000001F;
    border-radius: 8px;
    min-height: 140px;
    cursor: pointer;
}
.icon-wt-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;
}
.icon-wt-text h3, .icon-wt-text p{margin: 0;}
.icon-wt-text img{
    background-color: #000;
    border-radius: 50%;    
    width: 25px;
    height: 25px;
    padding: 5px;
    object-fit: cover;
}
.what-a{
    color: inherit;
    text-decoration: unset;
}
.m1-head {
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: var(--font-family);
    font-weight: 600;
}
.min-txt {
    font-size: 13px;
    letter-spacing: 0.4px;
    line-height: 20px;
    font-family: var(--font-family);
}
.whatsNewSection .MuiGrid-item:nth-child(2) .iconBox{background-color: #AD5CC70D ;}
.whatsNewSection .MuiGrid-item:nth-child(3) .iconBox{background-color: #F96B530D ;}
.whatsNewSection .MuiGrid-item:nth-child(4) .iconBox{background-color: #4471F90D ;}
.sec-head {
    text-align: left;
    font-family: var(--font-family);
}
.sec-head h2{
    letter-spacing: 0.5px;
    margin: 0;
    font-family: var(--font-family);
    font-size: 28px;
}
.sec-head p{
    margin: 5px 0 30px 2px;
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 22px;
    letter-spacing: 0.2px;
}
.rightArrow{
    overflow: hidden;
    border-radius: 50%;
    padding: 12px;
    border: 1px solid #2d2d2d5e;
    cursor: pointer;  
    width: 15px;
    height: 15px;  
}
.leftArrow{
    overflow: hidden;
    border-radius: 50%;
    padding: 12px;
    border: 1px solid #2d2d2d5e;
    transform: rotate(180deg);
    cursor: pointer;
    width: 15px;
    height: 15px;  
}
.arrowIcons{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    
}
.arrowIcons .not-allow{
    cursor: not-allowed;
}
.whatsNewSection {
    padding-top: 2em;
}

.sec-head h2 span{
    text-transform:lowercase;
}

@media (max-width: 1200px) and (min-width: 600px) {
    .whatsNewSection{
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .sec-head h2{font-size: 22px;}
    .sec-head p{font-size: 12px;line-height: 20px;margin-bottom: 0px;}
    
  }