.MuiPaper-root{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.count-txt{
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-family);
    color: #232323;
}
.count{
    font-size: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    color: #232323;
}
.dashboard-box h4{
    font-size: 16px;
    font-family: var(--font-family);    
    font-weight: 500;
}
.dashboard-box .right-cont {width: 100%;}
.s_count{
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 700;
    color: #232323;
}
.toppadding{padding-top: 40px;}
.toppaddingsm{padding-top: 20px;}
.bg-white{background-color: white;}
.ProfileInformationUI .MuiTable-root td, .ProfileInformationUI .MuiTable-root th{
    font-family: var(--font-family);
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #f0f0f0a8;
    padding: 10px;
}
.ProfileInformationUI .MuiTable-root th b{font-weight: 600;text-transform: uppercase;}
.statusBatch{
    color: #800080;    
    padding: 5px 10px 5px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 11px;
}
.app_table {box-shadow: none !important;border: 1px solid #e7e7e7;}
.width_xs{width: 16%;}
.right{text-align: right !important;}
.app_color{background-color: #fff2f3 !important;border: 1px solid #FF76CE;}
.process_color{background-color: #ecf8ff !important; border: 1px solid #07a2ff;}
.completed_color{background-color: #f5fbf6 !important; border: 1px solid #0aaf5b;}
.processing_color{
    background-color: #007fd62b;
    color: #007fd6;
}
.complete_color{
    color: #4bd963;
    background-color: #4bd96321;
}
.incomplete_color{
    background-color: #fdd6543d;
    color: #fdd654;
}
.approved_color{
    background-color: #e881fa45;
    color: #e881fa;
}
.rejected_color{
    background-color: #f1416c29;
    color: #f1416c;
}
.cursor{
    cursor: pointer;
}
.box-icon {
    width: 40px;
    height: 40px;
}
.appDetail{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: left;
}
.flex-grid-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-radius: 10px !important;
    padding-left: 15px !important;
}
.flex-grid-item img{width: 100%;}
.fav_cont .MuiGrid-item:nth-child(1) .category-outer-box{
    background-color: #eaf0ff;
}
.fav_cont .MuiGrid-item:nth-child(2) .category-outer-box{
    background-color: #ffe5e7;
}
.fav_cont .MuiGrid-item:nth-child(3) .category-outer-box{
    background-color: #fff3e6;
}
.fav_cont .MuiGrid-item:nth-child(4) .category-outer-box{
    background-color: #ebf8ed;
}
.fav_cont .MuiGrid-item:nth-child(5) .category-outer-box{
    background-color: #f6e8ff;
}
.fav_cont .category-outer-box {
    padding: 20px 10px 20px 15px;
    border-radius: 8px;
}
.fav_padding{
    padding-bottom: 25px;
    padding-top: 10px;
}
.fav_cont {border-radius: 8px;}
.app_table .css-i4bv87-MuiSvgIcon-root{width: 0.8em;height: 0.8em;}
.app_table .css-i4bv87-MuiSvgIcon-root:hover{fill: #D8071E;}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure the popup is on top of other content */
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    width: 100%;
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    float: right;
  }
  .f-right{float: right;}
  .no-margin{margin: 0;}
  .topmargin{margin-top: 20px;}
  .viewall-txt{
    color: #0831a7;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.5px;
  }
 .center{text-align: center;}
 .padd-10{padding: 10px;}
 .color_black{color: #232323;}
 .latest-app-table .MuiInputBase-root {
    width: auto !important;
}
.latest-app-table .MuiSelect-icon {
    fill: var(--primary);
    width: 27px !important;
    height: 27px !important;
    top: calc(50% - 15px) !important;
}
.dash-app-table th, .dash-app-table td{
    font-family: var(--font-family);
}
.bottompaddingsm{padding-bottom: 20px;}
.label-txt{font-size: 13px;font-family: var(--font-family);}
.viewall label{font-size: 14px;font-weight: 500; letter-spacing: 0.5px;font-family: var(--font-family);}
.no-padd-top{padding-top: 0 !important;}
.popup-content p{color: var(--secondary);}
.fvt-rootpad{padding-top: 23px;}
.center_banner_txt{
    margin: 0 auto;
    text-align: left;
    /* background-color: rgb(0 0 0 / 81%); */
    /* background: linear-gradient(110deg, #10321e, #492829); */
    background: linear-gradient(110deg, #10321ee6, #492829d1);
    padding: 10px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 28px;
    width: 35%;
    padding: 40px;
    border-radius: 20px;
}
.full_w{width: 100%;}

.center_banner_txt h2{
    font-size: 30px;
    letter-spacing: 0.5px;
    font-weight: 500;
    line-height: 45px;
    margin-top: 20px;
}
.center_banner_txt p{
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 300;
    line-height: 30px;
}
.center_banner_txt a{
    color: #c1c276;
    text-decoration: none;
    font-size: 17px;
    letter-spacing: 0.3px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}
.viewall-txt{text-decoration: none;}
.width_sm{width: 30%;}
.width_xss{width: 13%;}
.fvt-rootpad .right-cont p{
    font-size: 12px;
}
.badge{display: none;}
.fvt-rootpad .left-img{width: 50px;height: 50px;}
.fvt-rootpad .right-cont h4{font-size: 14px;}
.fvt-rootpad .category-outer-box{gap: 13px;}
.all-app .viewall .MuiGrid-item{text-align: left;}
.latest-app-table .MuiList-root{width: 100%;}
.latest-app-table .MuiTablePagination-toolbar{overflow: hidden;}
.all-app .viewall .MuiGrid-item{
    display: flex;
    flex-direction: row;
}
.all-app .viewall.MuiGrid-container{line-height: 22px;}
.all-app .viewall.MuiGrid-container.toppaddingsm {
    padding-top: 10px;
}

@media (max-width: 768px) {
    .count-txt {font-size: 13px;}
    .count{font-size: 23px;}
    .popup-content{
        max-width: -webkit-fill-available;
        margin-left: 10px;
        margin-right: 10px;
    }
    .center_banner_txt{font-size: 20px;width: 70%;padding: 10px 18px;border-radius: 10px;}
    .center_banner_txt a{font-size: 15px;}
    .center_banner_txt h2{font-size: 14px;margin-top: 0px;line-height: 18px;}
    .center_banner_txt p {font-size: 12px;line-height: 20px;margin: 5px 0px;}
    .box-icon {width: 30px;height: 30px;}
}
@media (max-width: 350px) {
    .lat-flex{display: flex;flex-direction: column;}    
}
@media (min-width: 768px) and  (max-width: 991px) {
    .center_banner_txt{width: 50%;padding: 40px;}
    .head-my{margin-left: 40px;}
    .mobile-search-icon{display: none;}

}
@media (min-width: 991px) and  (max-width: 1170px) {
    .all-app .css-1oqqzyl-MuiContainer-root:not(MuiContainer) {
        box-sizing: content-box; /* or initial; or unset; depending on your preference */
      }
    .all-app table, .dashUI table{min-width: 100%;}
}
@media (min-width: 991px) and  (max-width: 1130px) {
    .count-txt {font-size: 13px;}
    .count {
        font-size: 20px;
    }
    .box-icon {
        width: 35px;
        height: 35px;
    }
    body .aside-menu {width: 280px;}
    .right-cont{gap: 5px;}
    body .aside-menu{    margin-right: 24px;}
}
.banner_bg {
    background: linear-gradient(110deg, #05321eb3, #402829a3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.welcome_text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    color: white;
}