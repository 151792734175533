.left-label {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    letter-spacing: 0.5px;
  }
  .right-label {
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #6d6d6d;
    font-weight: 500;
  }

  .left-label {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    letter-spacing: 0.5px;
  }
  .right-label {
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #6d6d6d;
    font-weight: 500;
  }
 
  .side-title {
    background-color: #e6e6e6;
  }
  .side-title h3 {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 8px 5px 8px 11px;
    color: #000;
  }

  
  .main-title {
    text-align: center;
    font-size: 24px;
    color: #292929;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: ui-rounded;
  }
  .main-title-one {
    text-align: center;
    font-size: 28px;
    color: #292929;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: ui-rounded;
    margin-top: 0px;
  }
  .main-title-head {
    text-align: center;
    font-size: 24px;
    color: #292929;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: ui-rounded;
  }
  .main-title-headsecond {
    text-align: center;
    font-size: 24px;
    color: #292929;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: ui-rounded;
  }
  .itan {
    border-bottom: 3px solid;
  }
  .lorum {
    text-align: justify;
    font-size: 15px;
    color: #222;
  }
  .line-ht {
    line-height: 27px;
  }
  .key-li {
    font-size: 15px;
    letter-spacing: 0.5px;
  }
  
  .directorate {
    padding-left: 0px;
  }
  .directorate-he {
    margin-top: 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: ui-rounded;
  }
  
  .ministry-head {
    text-align: center;
    font-size: 24px;
    color: #292929;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0px;
    font-family: ui-rounded;
  }
  .center {
    text-align: center;
  }
  
  .board-hide {
    margin-bottom: 10px;
  }
  
  .full-width {
    width: 100%;
  }
  .heading-table {
    border: none;
  }
   
  .half-width {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin: 0px 4px;
  }
  .full-width {
    width: 99%;
    margin: 5px;
  }
  .amount-words {
    margin-top: 20px;
  }
  /* .amount-words div {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
    height: 20px;
  } */
  .heading-table {
    width: 100%;
  }
  .client_port p {
    margin: 0px;
  }
  .unified_addr{
    display: flex;
    flex-direction: column;
    color: #000;
    line-height: 20px;
  }
  .green-head {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #000;
  }
  .payment-registration-slip {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
  }
  .details {
    margin: 20px 0;
  }
  .d-flex-print {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .currency td{
    border-right: 1px solid;
  }
  .br-bt-line {
    border-bottom: 1px solid;
  }
  .amtTable th, .amtTable td{padding-left: 10px;}
  .flex-pdf{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin-top: 1rem;
  }
  .gr_box p{margin: 0px !important;}
  .gr_box.d_flex{
    display: flex;
  }
  .logo_wd{
    width: 20%;
  }
  @media print {
    table th,
    table td {
      font-size: 12px !important;
    }
    table td a {
      color: #0b452d !important;
      font-weight: 600;
    }
    .green-head {
      font-size: 15px !important;
    }
    h3 {
      font-size: 14px !important;
    }
    body {
      margin: 0;
      padding: 0;
    }
  }
  