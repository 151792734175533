.Services-Section.viewallList{
    margin-top: 0em !important;
    margin-bottom: 1.5em !important;    
}
.service-header {
    background: transparent linear-gradient(98deg, #000000 0%, #1d0002 50%, #420007ed 100%) 0% 0% no-repeat padding-box;
    padding: 20px 0;
    padding-bottom: 100px;
}
.service-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.service-header .left-div h6 {
    text-align: left;
    font: normal normal normal 20px/27px Noto Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 18px;
    margin: 0;
}
.service-header .left-div .desc {
    text-align: left;
    font: normal normal normal 20px/27px Noto Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 13px;
    display: block;
}
.service-page-detail {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: -80px;
}
.viewallList .service-page-detail{padding: 25px 5px 25px 5px;}

.btn-group-cancel-apply {
    width: 100%;
    text-align: right;
    margin-top: 10px;
}

.btn-group-cancel-apply .btn.btn-cancel {
    background: #6f6f6f;
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding: 6px 30px;
    text-transform: capitalize;
    font-family: var(--font-family);
    margin: 0 15px;
}

.btn-group-cancel-apply .btn.btn-submit {
    background: var(--secondary);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding: 6px 30px;
    text-transform: capitalize;
    font-family: var(--font-family);
}