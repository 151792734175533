.service-page {
  margin-bottom: 20px;
  font-family: "Noto Sans", sans-serif;
}
.service-page .service-header {
  background: transparent
    linear-gradient(98deg, #000000 0%, #1d0002 50%, #420007ed 100%) 0% 0%
    no-repeat padding-box;
  padding: 20px 0;
  padding-bottom: 100px;
}

.service-header .left-div h6 {
  text-align: left;
  font: normal normal normal 20px/27px Noto Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 18px;
  margin: 0;
  text-transform: capitalize;
}

.service-header .left-div .desc {
  text-align: left;
  font: normal normal normal 20px/27px Noto Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 13px;
  display: block;
}

.service-header .left-div .desc a{
  color: #ffffff;
  text-decoration: none;
}

.service-header .service-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-header .service-header-inner .feature-url{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.service-header .service-header-inner .list-detail{
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 7px;
  width: fit-content;
}

.service-header .service-header-inner .list-detail .icon{
  font-size: 13px;
  margin-right: 7px;
  border: 1px solid var(--secondary);
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  background-color: var(--secondary);
}

.service-header .filter-icon {
  cursor: pointer;
  color: #fff;
  font-size: 28px;
  padding-left: 5px;
}

.service-page-detail {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: -80px;
}

.service-page table {
  width: 100%;
  table-layout: auto;
  border-spacing: 0;
  border-collapse: collapse;
}

.service-page table .left {
  text-align: left;
}

.service-page table .right {
  text-align: right;
}

.service-page .table-scroll {
  overflow-x: auto;
}

.service-page table th,
.service-page table td {
  border-bottom: 1px solid #ddd;
  padding: 15px 40px;
  font-size: 15px;
}

.service-page table thead tr {
  background-color: #e7e7e7;
}

.service-page .btn-seemore {
  outline: none;
  padding: 9px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  border: none;
  font-size: 15px;
  margin: 15px auto;
  position: relative;
  padding-right: 30px;
}

.service-page .btn-seemore .arrow {
  position: absolute;
  right: 10px;
  font-size: 14px;
}

.service-page .btn-seemore .arrow.up {
  top: 6px;
}

.service-page .btn-seemore .arrow.down {
  bottom: 6px;
}

.filter-dropdown ul {
  width: 300px;
  background: #fff;
  padding: 15px;
  text-align: left;
  border-radius: 4px;
}

.filter-dropdown .form-group label {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.service-page .btn-add-tcc,
.tcc-add .text-right .btn-submit,
.tcc-validate .btn-submit {
  outline: none;
  padding: 9px 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  border: none;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.service-page .btn-add-tcc:hover,
.tcc-add .text-right .btn-submit:hover,
.tcc-validate .btn-submit:hover {
  background-color: var(--secondary);
}

.service-page .btn-add-tcc .plus-icon {
  margin-right: 7px;
}

.tcc-view-list {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.tcc-view-list .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tcc-view-list .top .left {
  color: var(--primary);
  font-weight: 500;
  font-size: 16px;
}

.tcc-view-list .top .right {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #808080;
}

.tcc-view-list .top .right .clock-icon {
  font-size: 15px;
  margin-right: 3px;
}

.tcc-view-list .mid {
  padding: 13px 0;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.tcc-view-list .bot {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tcc-view-list .bot .left {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
}

.tcc-view-list .bot .left .field {
  width: 25%;
  font-size: 15px;
  font-weight: 500;
}

.tcc-view-list .bot .left .field.tcc-number {
  color: #28a745;
}

.tcc-view-list .bot .left .field span {
  font-size: 14px;
  color: #808080;
  display: block;
}

.tcc-view-list .bot .right {
  width: 25%;
  text-align: right;
}

.tcc-view-list .bot .right .status {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
}

.tcc-view-list .bot .right .status .status-icon {
  font-size: 19px;
  margin-right: 3px;
}

.tcc-view-list .bot .right .status.not-valid {
  color: #bd2130;
}

.tcc-view-list .bot .right .status.success {
  color: #28a745;
}

.tcc-view-list .bot .right .status.pending {
  color: #ffc107;
}

.service-page-detail .btn-add-tcc {
  margin: 25px auto;
}

.tcc-add,
.tcc-validate {
  padding: 25px;
  text-align: left;
}

.tcc-add .form-group,
.tcc-validate .form-group {
  margin-bottom: 20px;
}

.tcc-add .form-group label,
.tcc-validate .form-group label,
.search-page .form-group label {
  font-size: 15px;
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 5px;
}

.tcc-add .form-group input,
.tcc-add .form-group .MuiSelect-select,
.tcc-validate .form-group input,
.search-page .form-group input {
  font-size: 15px;
  padding: 10px 15px;
}

.tcc-add .form-group .search input {
  padding-left: 0;
}

.tcc-add .form-group label .css-wgai2y-MuiFormLabel-asterisk,
.tcc-validate .form-group label .css-wgai2y-MuiFormLabel-asterisk {
  color: var(--red);
}

.tcc-add .text-right {
  text-align: right;
}

.tcc-add .text-right .btn-submit {
  margin: 20px 0;
  margin-left: auto;
}

.tcc-validate .btn-submit {
  justify-content: center;
  width: fit-content;
  padding: 9px 35px;
  margin-top: 25px;
}

.tcc-validate-detail {
  text-align: left;
  padding: 25px;
  padding-top: 0;
}

.tcc-validate-detail .ttl {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.tcc-validate-detail .field-li {
  display: flex;
  padding-bottom: 10px;
  color: var(--primary);
  font-size: 15px;
}

.tcc-validate-detail .field-li span {
  color: #000;
  min-width: 150px;
}

.service-page .MuiLoadingButton-loadingIndicator {
  color: #fff;
}

.color-primary {
  color: var(--primary);
}

.error {
  color: var(--secondary);
  font-size: 15px;
}

.facility-list {
  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
}

.facility-list .name {
  font-size: 16px;
  color: var(--primary);
}

.facility-list .desc {
  font-size: 15px;
  margin-top: 5px;
}

.no-br-bg{
  border: none;
}

.no-br-bg .form-loader{
  background-color: #fff;
}

@media (max-width: 768px) {
  .tcc-view-list .bot .left .field {
    width: 50%;
    padding-bottom: 7px;
  }

  .tcc-view-list .bot .left,
  .tcc-view-list .bot .right {
    width: 100%;
  }
}

@media (max-width: 567px) {
  .service-page table th,
  .service-page table td {
    padding: 15px 10px;
  }

  .tcc-view-list .top,
  .service-header .service-header-inner {
    flex-wrap: wrap;
  }

  .filter-dropdown ul {
    width: 280px;
  }

  .tcc-validate .btn-submit {
    margin-top: 0;
  }
}
