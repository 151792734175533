.static-detail{
    margin-bottom: 25px;
    padding: 15px;
    text-align: left;
}

.static-detail p{
    margin-top: 0;
}

.static-detail .center{
    text-align: center;
}