.form-section1 .contact-information{
    background-color:#ffff ;
    padding-top: 2px;
    border-radius: 3px
}

.form-section1  .contact-information h2{
color:#000;
background-color:#f1f2f8;
font-size: 13px;
padding: 10px 10px;
text-align: left;
border-left: 2px solid#636fb1;

}
.form-section1 .contact-information .p-tag{
color:#215487;
font-size: 14px;
font-weight: 600;
letter-spacing: -0.2px;
margin-bottom: 8px;
text-align: left;
font-family: var(--font-family);
display:flex;
}
.form-section1 .box-content1{

padding-top: 1rem;
padding-bottom: 8rem;
padding-right: 2rem;
padding-left: 2rem;
}
.form-section1 .errorMsg {
color: var(--red);
font-size: 12px;
margin-top: 2px;
font-weight: 500;
text-align: left;
}
.form-section1 .left{
    width: 18px;
    height: 18px;
}
.form-section1 .form-sec{
display: flex;
align-items: center;
justify-content: space-between;

}

.form-section1 .form-group .label {
color:#215487;
font-size: 12px;
font-weight: 600;
letter-spacing: -0.2px;
margin-bottom: 8px;
text-align: left;
font-family: var(--font-family);
}


.form-section1 .form-group label .MuiFormLabel-asterisk {
color: var(--red);
padding-left: 2px;
}

.form-section1 em{
font-style: normal;
font-size: 12px;
font-family: var(--font-family);
font-weight: 400;
text-align: left;
color:#dbdbdb;
}
.form-section1 input{
font-style: normal;
font-size: 12px;
font-family: var(--font-family);
font-weight: 400;
text-align: left;
}
.form-section1 .Name{
height: auto;
line-height: 1.4375em;
font-style: normal;
font-size: 12px;
font-family: var(--font-family);
font-weight: 400;
text-align: left;
}
.form-section1 .submit{
display: flex;
align-items: center;
justify-content: flex-end;

}
.form-section1 .btn{
background-color:#ce1126;
color: #fff;
font-family: var(--font-family);
font-weight: 600;
float: right;
margin: 15px 0px;
padding: 0.5rem 1rem;
font-size: 13px;
}
.form-section1 .btn1{
background-color:#fff;
color:#000;
font-family: var(--font-family);
font-weight: 600;
margin: 15px 7px;
padding: 0.5rem 1rem;
font-size: 13px;
border: 1px solid #ddd;

}
.form-section1 .btn:hover{
color: #fff;
background-color:#ee5668 ;
}

.form-section .css-19kzrtu{
    padding: 0px;
} 
.form-section1 .css-1oqqzyl-MuiContainer-root {
    padding-left: 0px; 
     padding-right: 0px; 
  }
.form-section1 .box-border{
    border: 1px solid #ddd;
   padding-bottom: 1rem;
    border-radius: 7px;
}
.form-section1 .btn .right{
width: 20px;
height: 20px;
}
.form-section1 .next{
display: flex;
align-items: center;
font-weight: 600;
font-family: var(--font-family);
}
.form-section1 .previous{
display: flex;
align-items: center;
font-weight: 600;
font-family: var(--font-family);
font-size: 13px;
}
.form-section1 .select-field{
height: auto;
min-height: 1.4375em;
text-align: left;
font-style: normal;
font-size: 12px;
font-family: var(--font-family);
font-weight: 400;
text-align: left;

}






@media (max-width: 576px){



}