.Services-Scheme-Section .category-outer-box {
    border: 1px solid #00000024;
    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: #fff;
    box-shadow: 0px 1px 6px #0000000A;
}
.Services-Scheme-Section .left-img {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    height: 60px;    
    padding: 10px;
    box-sizing: border-box;
}
.BusinessService.Services-Scheme-Section {
    background-color: #FAFAFA;
    padding-top: 0;
    padding-bottom: 0;
}
.Services-Scheme-Section .MuiGrid-item:nth-child(1n + 0) .left-img{
    background-color: #EEF5D9;
}
.Services-Scheme-Section .MuiGrid-item:nth-child(2n + 0) .left-img{
    background-color: #F9E7FF;
}
.Services-Scheme-Section .MuiGrid-item:nth-child(3n + 0) .left-img{
    background-color: #F96B531A;
}
.Services-Scheme-Section .MuiGrid-item:nth-child(4n + 0) .left-img{
    background-color: #21C9A51A;
}
.Services-Scheme-Section .MuiGrid-item:nth-child(5n + 0) .left-img{
    background-color: #FFDDDD;
}
.Services-Scheme-Section .MuiGrid-item:nth-child(6n + 0) .left-img{
    background-color: #DBEFF8;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child .left-img{
    background-color: #6e6b65;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child(1n + 0) .left-img{
    background-color: #E69F23;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child(2n + 0) .left-img{
    background-color: #0DA1B8;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child(3n + 0) .left-img{
    background-color: #F96B53;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child(4n + 0) .left-img{
    background-color: #21C9A5;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child(5n + 0) .left-img{
    background-color: #4471F9;
}
.BusinessService.Services-Scheme-Section  .MuiGrid-item:nth-child(6n + 0) .left-img{
    background-color: #AD5CC7;
}
.Services-Scheme-Section .right-cont {
    width: 77%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    line-height: 21px;
}
.Services-Scheme-Section .right-cont h4, .Services-Scheme-Section .right-cont p{margin:  0;font-family: var(--font-family);    letter-spacing: 0.5px;}
.Services-Scheme-Section .right-cont p {
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: var(--font-family);
}
.Services-Scheme-Section .right-cont h4{
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-family: var(--font-family);
}
.Services-Scheme-Section .view-btn {
    border: 1px solid #CE1126;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
}
.Services-Scheme-Section .css-mhc70k-MuiGrid-root{display: flex;align-items: center;}
.Services-Scheme-Section .view-btn span {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #CE1126;
    font-family: var(--font-family);
    font-weight: 600;
}
.Services-Scheme-Section .flex-view-btn{
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 10px;
}
.Services-Scheme-Section {
    background-color: #FAFAFA;
    padding-top: 3em;
    padding-bottom: 3em;
}
.Services-Scheme-Section .sec-head h2{margin-bottom: 10px;}

@media (max-width: 1200px) and (min-width: 600px) {
    .Services-Scheme-Section{
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 567px) {
    .Services-Scheme-Section .right-cont h4{
        overflow: unset;
        text-overflow: unset;
        display: block;
    }
}