

.success-section {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 25px 0px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.success-title {
    color: #42a975;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.5px;
    margin: 0;
}
.failed-title {
    color: var(--secondary);
    font-weight: 600;
}
.success-gif {width: 60px;}

.payment-details {
    text-align: left;
    margin: 20px 0;
}

.print-button {
    background-color: #42a975;
    color: #ffffff;
    padding: 7px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    letter-spacing: 0.5px;
}
.print-button:hover {
    background-color: #42a975;
}
.pay-icon{
    width: 100%; border-bottom: 0px solid #d9d9d9; padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    padding-top: 10px;
    background-color: #f7faff;
}
.pay-head{
    width: 100%;border-bottom: 0px solid #d9d9d9;
    padding-bottom: 25px;
}

.payment-details{width: 100%;}
.pay-table{width: 100%;}
.pay-table th{
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.5px;
    border-bottom: 0px solid #dddddd8f;
    color: #555555;
    font-family: 'Inter';
}
.pay-table td{
    font-size: 14px;
    color: #414145;    
    font-weight: 400;
    border-bottom: 0px solid #dddddd8f;
    padding-bottom: 15px;
    padding-top: 5px;
    font-family: 'Inter';
    /* opacity: 0.85; */
}
.pay-table.sub-pay td{text-transform: capitalize;    padding-bottom: 8px;}
.pay-table.sub-pay th{padding-bottom: 15px;}
.pay-success .payAmt{
    font-size: 15px;
    color: #393c41;
    /* opacity: 0.98; */
    font-family: 'Inter';
    margin-top: 10px;
}
.wd-xs{width: 20%;}
.pay-border {
    border: 1px dashed #8D8D8D;
    /* margin-left: 2em;
    margin-right: 2em; */
    border-radius: 3px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 85%;
}
p.pay-ref-no-note{
    margin: 0;
    font-family: 'Inter';
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 3px;
    letter-spacing: 0.5px;    
    font-size: 12px;
    text-transform: inherit;
}
p.pay-ref-no-note.red-tx{
    color: #CF1010;
}
p.pay-ref-no-note.gray-txt{
    color: #727285;
    margin-top: 15px;
}
.failed p{
    padding-left: 5%;
    font-weight: 500;
    color: #414145;
    text-transform: inherit;
    font-size: 14px;
    padding-right: 10px;
}
.failed .reason-txt{
    padding-left: 5%;
    font-weight: 600 !important;
    font-size: 15px;
    letter-spacing: 0.5px;
}
.failed-title{margin: 0;font-size: 18px;}
.pay-success{margin-bottom: 20px;}
.close-icon path{
    fill: #d8071e;
}
.close-icon p{
    color: #414145;
}
.amount-field{visibility: hidden;height: 0.1px;}
.payFailed{margin-bottom: 20px;}
.print-button.close{
    background-color: #727674;
}
.payFailed .pay-head p{color: #414145;}
.pay-icon path{
    fill: #42a975;
}
.pay-table{
    padding-left: 5%;
    padding-right: 5%;
}
@media print{
    .HeaderUI header{display: none;}
    .FooterUI, .service-header{display: none;}
    .print-button{display: none;}    
    .success-section {border: 1px solid #e0e0e0;box-shadow: none;}
    /* .pay-icon{border-left:1px solid #e0e0e0;border-top: 1px solid #d9d9d9;} */
    
    .pay-head{border-bottom: 1px solid #d9d9d9;}
    .go-home { display: none; }
    .payment-details{margin-bottom: 0;}
    .pay-icon{border: 1px solid #e0e0e0;}
}

@media print and (orientation: portrait) {
    #printablediv { margin-top: 15%; }
}

/* Landscape mode styles */
@media print and (orientation: landscape) {
    #printablediv { margin-top: 8%; }
    .pay-icon{border: 1px solid #e0e0e0;gap: 10px;padding-bottom: 5px;}
    /* .pay-icon { border-right: 1px solid #e0e0e0; border-top: 1px solid #d9d9d9; } */
    .success-section {border: 1px solid #e0e0e0;box-shadow: none;}
}

.go-to-home {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    padding-top: 20px;
}

.go-home {
    background-color: #D8071E;
    color: #ffffff;
    padding: 7px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    letter-spacing: 0.5px;
}

.go-home:hover {
    background-color: #D8071C;
}
.pay-head .pay-ref-no-txt {
    font-weight: 800;
    color: #414145;
}
.pay-table.bank-table td{line-height: 25px;}
.pdfloader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 9999;
  }
@media(max-width:767px) {
    .pay-table.sub-pay th{width: 25%;}
    .payment-page h3{font-size: 15px;}
    .pay-ref-no-txt.pay-no{font-size: 16px !important;}
}