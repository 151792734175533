.FooterUI {
    position: relative;
    margin-top: auto;
}
.FooterUI .footer-section{
    background:#242424;
     padding: 2rem 5rem; 
    color: #e3e3e3;
    display: flex;
    gap: 20px;
}
.FooterUI .footer-section .leftCol {
    width: 40%;
    display: inline-block;
}
.FooterUI .footer-section .rightCol {
    width: 60%;
    display: inline-flex;
    padding-left: 20px;
    gap: 20px;
}
.FooterUI .footer-section .rightCol .footer-col {
    width: 30%;
}
 .FooterUI .footer-section .rightCol .footer-col:nth-child(2){
    width: 70%;
} 
.FooterUI .footer-section ul {
    padding-left: 0;
    list-style: none;
    text-align: left;
    margin:0;
}
.FooterUI .footer-section .leftCol .text1 {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    color: #e3e3e3;
    font-weight: 600;
    padding: 0px 0px;
    border-radius: 10px;
    gap: 10px;
    margin-top: -5px;
}
.FooterUI .footer-section .leftCol .text1 .title-text {
    display: flex;
    flex-direction: column;
    color:#e4e4e4;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.8px;
}
.FooterUI .footer-section .leftCol .text1 .title-text span {
    color:#e4e4e4;
    font-weight: 200;
    line-height: 27px;
    font-size: 13px;
    letter-spacing: 0.3px;
}
.FooterUI .text {
    text-align: left;
}
.FooterUI .text h5, .FooterUI .text h4 {
    text-align: left;
    font-size: 17px;
    font-weight: 500;
     margin: 0;
    display: inline-block;
    color: #e4e4e4;
    letter-spacing: 0.5px;
   
}
.FooterUI .rightCol .pad{
    padding: 30px 0px;
}
.FooterUI .rightCol .pad1{
    padding: 30px 0px;
}
.FooterUI .text h4 {
    border-bottom: none;
    padding-bottom: 0;
}
.FooterUI .footer-section .leftCol .detail {
    text-align: left;
}
.FooterUI .bor{
    border: 1px solid #e4e4e4;
    opacity: 0.3;
   
}
.FooterUI .footer-section .leftCol .detail .para {
    font-weight: 300;
    font-size: 13.5px;
    letter-spacing: 0.8px;
    color:#e4e4e4bd;
}
.FooterUI .footer-section .rightCol .detail .address-icon {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    gap: 10px;
    text-align: left; 
    align-items: center;
    margin-bottom: 15px;
}
.address-icon .icon-img {
    width: 20px;
}
.FooterUI .footer-section .rightCol .detail .address-icon p {
    font-size: 13.5px;
    font-weight: 300;
    color: #e4e4e4bd;
    letter-spacing: 0.8px;
    margin: 0;
    padding: 0;
}
.FooterUI .icon-img img{
    width: 20px;
    height: 20px;
}
.FooterUI .icon-img .icon1{
    width: 17px;
    height: 17px;
}
.FooterUI .footer-section .rightCol .detail-img {
    background: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.FooterUI .footer-section .rightCol .detail-img img {
    width: 100%;
}
.FooterUI .footer-section .detail ul li {
    position: relative;
    display: block;
    line-height: 30px;
    margin-bottom: 22px;
    color: #e4e4e4bd;    
    text-decoration: none;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: initial;
    cursor: pointer;
    font-size: 13.5px;
    letter-spacing: 0.8px;
}
.FooterUI .footer-section .detail ul li:last-child {
    margin-bottom: 0;
}
.FooterUI .footer-section .detail ul li a {
    color: var(--white);
    text-decoration: none;
}
/* .FooterUI .footer-section .detail ul li:before {
    content: '';
    width: 13px;
    height: 2px;
    background: var(--white);
    border-radius: 10px;
} */
.FooterUI .copy-right {
   
    color: #e3e3e3;
    font-size: 13px;
    background:#242424;
    padding: 0rem 5rem; 
}
.FooterUI .copy-right .bot {
    color:#e4e4e4bd;
    font-size: 13.5px;
    text-decoration: none;
    font-weight:300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0.8px;
}

.FooterUI .copy-right .bot a{
    color: #e4e4e4bd;
    text-decoration: none;
    margin-left: 10px;
}
.FooterUI .rightCol 
.FooterUI .footer-section .rightCol .detail .address-icon .icon-img img {
    width: 20px;
    height: 20px;
    color: #e3e3e3;
   
}
.FooterUI .download{
    text-align: left;
    margin-top: 30px;
}
.FooterUI .download img{
    width: 150px;
    margin-right: 10px;
}
@media(max-width:767px){
    .FooterUI .footer-section {
        flex-direction: column;
        padding: 1.5rem;
    }
    .FooterUI .footer-section .leftCol,.FooterUI .footer-section .rightCol{
        width: 100%;
    }
    .FooterUI .footer-section .rightCol {
        padding-left: 0;
        flex-wrap: wrap;
    }
    .FooterUI .footer-section .rightCol .footer-col,.FooterUI .footer-section .rightCol .footer-col:nth-child(2) {
        width: 100%;
    }
    .FooterUI .footer-section .rightCol .detail-img img {
        width: auto;
        max-width: 100%;
    }
    .FooterUI .footer-section .rightCol .detail-img {
        display: inline-flex;
        width: auto;
        float: left;
    }
    .FooterUI .text h5 {margin: 0;}
    .FooterUI .footer-section .leftCol .text1 img {
        width: 60px;
    }

    .FooterUI .footer-section .leftCol .text1{
        width: fit-content;
        padding: 5px 10px;
    }
}

@media (max-width: 567px){
    .FooterUI .footer-section .leftCol .text1 img{
        width: auto;
        height: 50px;
    }

    .FooterUI .footer-section .leftCol .text1 .title-text{
        font-size: 17px;
    }

    .FooterUI .text h5, .FooterUI .text h4{
        font-size: 14px;
    }

    .FooterUI .footer-section .detail ul li a{
        font-size: 14px;
    }

    .FooterUI .copy-right a{
        font-size: 12px;
        flex-direction: column; 
    }
    .FooterUI .copy-right {
        padding: 0rem 1rem;
       
    }
    .FooterUI .copy-right .bot {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
    }
}