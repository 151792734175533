.custom-popup .popup-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 98;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  animation: opacityFade 0.1s forwards alternate linear;
}

.custom-popup .popup-outer {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  animation: slideTop 0.2s forwards alternate linear;
  z-index: 99;
  width: 100%;
  height: 100%;
}

@keyframes slideTop {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.custom-popup .popup-inner {
  width: 450px;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #b5b5b5;
}

.custom-popup .popup-header {
  margin-top: -40px;
  text-align: center;
}

.custom-popup .img-box {
  width: 70px;
  height: 70px;
  background-color: #fff;
  margin: auto;
  border: 1px solid #b5b5b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.custom-popup .img-box img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.custom-popup .img-box .close-icon {
  color: #dc3545;
  font-size: 60px;
}

.custom-popup .popup-header span.error {
  color: #dc3545;
}

.custom-popup .popup-header span {
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 10px;
  display: block;
  font-weight: 600;
}

.custom-popup .popup-header span.success {
  color: #4bae4f;
}

.custom-popup .popup-body {
  padding-bottom: 20px;
}

.custom-popup .popup-body .top {
  margin: 20px 0;
  background-color: #fff;
  padding: 16px;
  font-size: 15px;
  font-weight: 600;
}

.custom-popup .popup-body .top span {
  display: block;
  color: var(--secondary);
}

.custom-popup .field {
  margin: auto;
  width: 50%;
}

.custom-popup .popup-body .field-li {
  display: flex;
  padding-bottom: 10px;
  color: var(--primary);
  font-size: 15px;
  text-align: left;
}

.custom-popup .popup-body .field-li span {
  color: #000;
  min-width: 120px;
}

.custom-popup .popup-footer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.custom-popup .popup-footer button {
  outline: none;
  padding: 9px 25px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--secondary);
  color: #fff;
  border: none;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

@media (max-width: 567px) {
  .custom-popup .popup-inner {
    width: 95%;
  }

  .custom-popup .field {
    width: 85%;
  }
}
