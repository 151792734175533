.help-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    font-family: var(--font-family);
    gap: 25px;
}
.help-head h2, .help-head p{margin: 0;}
.help-head h2{
        font-size: 30px;
        letter-spacing: 0.5px;  
}
.info-txt {
    font-size: 18px;
    width: 70%;
    line-height: 30px;
    letter-spacing: 0.5px;
}
.callNo {
    background-color: #D8071E;
    color: white;
    width: fit-content;
    text-align: center;
    padding: 7px 15px 7px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 0.5px;
}
.helpBox {
    border: 1px solid #d5eaff;
    background-color: #fafdff;
    padding: 10px 40px 0px 40px;
    border-radius: 25px;
}
.HelpBannerSection {
    padding-top: 3em;
    padding-bottom: 3em;
}
.HelpBannerSection .helpBox img{
    max-width: 100%;
}
@media (max-width: 1200px) and (min-width: 600px) {
    .HelpBannerSection{
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 768px){ 
    .help-head h2 {font-size: 23px;}
    .help-head{gap: 10px;}
    .helpBox img{width: 100%;}
    .HelpBannerSection {padding-top: 2em;padding-bottom: 2em;}
}
