.payment-add{
    padding: 0px 25px;
    text-align: left;
}
.payment-add label{
    font-size: 15px;
    color: var(--primary);
    font-weight: 500;
    margin-bottom: 5px;
    width: 45%;
    font-family: 'Inter';
}
.payment-add .MuiInputBase-root{
    width: 50%;
}
.service-page-detail .btn.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding: 12px 25px;
    text-transform: uppercase;
    font-family: var(--font-family);
    width: 100%;
  }
.flex-grid{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
}
.payment-add .MuiFormGroup-root{
    margin-bottom: 20px;
}
.amount-field .MuiFormLabel-root{
    width: 53%;
}

.amount-field .MuiInputBase-root{
    width: 100%;
}
.flex-grid:not(:has(:first-child)) {
    display: none;
}
.mda-group{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
}
.MuiFormLabel-asterisk{color: var(--red);}
.payPage .MuiFormControl-root{width: 50%;}
.payPage .amount-field{width: 50%;}
.errorMsg.clear_b{
    clear: both;
    width: 100%;
    text-align: center;
    margin-right: 22%;
}
.payment-add .pay-btn{
    background-color: #405bac;
    color: #fff;
    padding: 7px 25px 7px 25px;
    font-weight: 500;
    font-family: 'Inter';
    width: fit-content;
}
.flex-grid.lastDiv{
    flex-wrap: wrap;flex-direction: column;    
    margin-right: -7%;
    margin-bottom: 0px;
    height: 80px;
}
.payment-page p{
    margin: 0;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 3px;
    color: #555555;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}
.payment-page h3{
    margin: 5px;
    font-family: 'Inter';
    opacity: 0.98;
    letter-spacing: 0.5px;
    font-size: 18px;
    color: #089451;
    font-weight: 500;
}
.pay-ref-no-txt.pay-no{
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
}

.service-name{color: var(--primary) !important;font-size: 18px !important;}
.service-amt{color: #393c41 !important;font-size: 18px !important;}
.amount-field input{height: 0.1px;}
.sub-font{
    color: var(--primary)  !important;
}

.amount-label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.labels {
    display: flex;
    flex-direction: column;
}

.amount-label label {
    width: 100%;
    margin-left: 6%;
    margin-top: 2px;
}

.row-center {
    margin-top: 1%;
}

.pay-amount {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.pay-amounts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-option {
    display: flex !important;
    flex-direction: row !important;
}

.payment-btn {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
}
.payment-btn-start{
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    margin-top: 3%;
    margin-left: 1%;
}
.bank-payment {
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    background-color: #F5F5F5;
    width: 50%;
    padding: 18px 15px;
}

.bank-pay-txt {
    color: #1E1E2D !important;
    font-size: 13px !important;
}

.bank-group {
    flex-direction: row !important;
}

.bank-detail {
    color: #393c41 !important;
    font-size: 14px !important;
}

.bank-head {
    color: #393c41 !important;
    font-size: 13px !important;
    margin-bottom: 0px;    
}
.paymentOptionClass label{
    width: 100%;
    color: rgba(0, 0, 0, 0.87);    
}
.row-center.paymentOptionDetails{margin-top: 4%;}
.bank-payment .paymentOptionDetails .MuiFormGroup-root {
    margin-bottom: 3px;
}
.amountRow .MuiFormGroup-root, .bankRow .MuiFormGroup-root{margin-bottom: 0px;}