html,
body,
body #root,
.App.NoAccessPage,
.App.NoAccessPage .admin-layout,
.App.NoAccessPage .admin-layout div {
    height: 100%;
}
.App.NoAccessPage .admin-layout .NoAccess{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
}
h1.heading-404 {
    color: var(--primary);
    font-size: 55px;
    font-family: var(--font-family);
    font-weight: 900;
}
.App.NoAccessPage .admin-layout .NoAccess button {
    background: var(--primary-light);
    color: var(--primary);
    font-size: 18px;
    padding: 15px 30px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-family: var(--font-family);
    font-weight: 600;
    letter-spacing: .3px;
}
@media(max-width:767px){
    h1.heading-404 {
        font-size: 30px;
    }
    .App.NoAccessPage .admin-layout .NoAccess button {
        font-size: 15px; 
        padding: 10px 20px;
    }
}