.BannerSection {
    position: relative;
    height: 100%;
}
.BannerSection .css-1f8sh1y{
    height: 400px;
}
.BannerSection img {
    width: 100%;
    height: 520px;
    object-fit: cover;
}

.loading-banner {
    background: #e7e7e7;
    padding: 20px 0;
    width: 100%;
    height: 400px;
    display: flex;
}

.loading-banner .banner-content{
    padding: 20px;
    margin-bottom: 25px;
    width: 50%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.center_banner_txt .badge{
    position: absolute;
    top: 0;
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: 200;
    background-color: #d8071e;
    padding: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.search_wtd{
    width: 50%;
    height: 48px;
    border-radius: 8px;
}
.bottom_sp{
    margin-bottom: 18px;
}
.search_btn_ban{
    padding: 10px 20px 10px 20px !important;
}
.search_wtd .MuiOutlinedInput-input{
    padding: 12.5px 17px;
}
span.color_txt {
    color: white;
    font-size: 0.9rem;    
    font-weight: 400;
    font-family: 'Inter';
}

.search-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    height: 100%;
}
@media (max-width: 1400px){
    .BannerSection img {
        height: 450px;
    }
}

@media (max-width: 1200px){
    .BannerSection img {
        height: 400px;
    }
}


@media (max-width: 991px){
    .BannerSection img {
        height: 300px;
    }
}

@media (max-width: 768px){
    .BannerSection img {
        height: 250px;
    }
    .search_wtd {
        width: auto;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .search_btn_ban {
        padding: 10px 15px 10px 15px !important;        
    }
    .search_wtd .MuiOutlinedInput-input {
        padding: 12.5px 0px;
    }
}

@media (max-width: 567px){
    .loading-banner {
        height: 170px;
    }

    .loading-banner .banner-content .movie--isloading{
        display: none;
    }

    .loading-banner .banner-content .movie--isloading:first-child{
        display: block;
    }

    .BannerSection img {
        height: 180px;
    }
}