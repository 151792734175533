.ForgotUI{
    margin-bottom: 70px;
}
.ForgotUI .heading,
.head-with-line {
    color: var(--primary);
    font-size: 22px;
    position: relative;
    width: auto;
    display: inline-block;
    font-weight: 500;
    margin: 35px auto;
}
.ForgotUI .forgot-link{
    float: right;
    color: #444;
    font-size: 12px;
    padding-bottom: 15px;
} 

.ForgotUI .heading:before,
.head-with-line::before {
    position: absolute;
    content: '';
    border: 1px solid;
    width: 40px;
    left: -50px;
    top: 50%;
    border-radius: 10px;
}
.ForgotUI.form-group.button.btn.btn-primary {
    width: 0px!important;
    margin: auto!important;
}
.ForgotUI .heading:after,
.head-with-line::after {
    position: absolute;
    content: '';
    border: 1px solid;
    width: 40px;
    right: -50px;
    top: 50%;
    border-radius: 10px;
}
.ForgotUI .head-h2, .ForgotUI .head-h3 {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}
.ForgotUI .head-h3 {
    color: var(--secondary);
    width: fit-content;
    padding-bottom: 10px;
    margin-bottom: 25px;
}
.ForgotUI p {
    text-align: justify;
    line-height: 20px;
    font-size: 14px;
    color: #464646;
    font-weight: 500;
    margin: 28px 0;
}
.ForgotUI .formSection {
    text-align: left;
   
}
.ForgotUI .formSection .head-h3 {
    margin-top: 0;
}
.ForgotUI .grid-con{
    margin: auto;
}
.ForgotUI .MuiCollapse-wrapperInner .MuiPaper-root {flex-direction: row;}
.MuiAlert-root{flex-direction: row !important;}
@media (max-width: 576px){
    .ForgotUI .heading,
    .head-with-line {
        font-size: 19px;
        margin: 25px auto;
    }
    .ForgotUI .heading:before,
    .head-with-line::before ,
    .ForgotUI .heading:after,
    .head-with-line::after {
       content: none;
    }    
    .ForgotUI .formSection{
        font-size: 1.5rem;
        margin-top: 15px;
    }
    .ForgotUI .grid-con{
        width: 100%;
        margin: auto;
    }
}