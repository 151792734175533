.grid-boxes .outer-box{
    position: relative;    
}

.grid-boxes .top-img{
    width: 80px;
    height: 80px;
    background-color: #e75cb4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
}

.grid-boxes .top-img img{
    max-width: 100%;
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.grid-boxes .inner-box{    
    background-color: #fff;
    padding: 20px 25px 20px;
    border-radius: 10px;
    text-align: center;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #0b437b36;
}
.PopularServicesSection{    
    background: linear-gradient(110deg, #10321ee6, #492829d1);
    margin-top: 5em;
    margin-bottom: 5em;
    height: 245px;
}
.PopularServicesSection .MuiGrid-spacing-xs-3{padding-top: 40px;}
.freq-head {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    color: #FFFFFF;
    font-family: var(--font-family);
}
.freq-head h2, .freq-head p{margin: 0;}
.freq-head h2{font-size: 30px;letter-spacing: 0.7px; font-weight: 500;}
.freq-head p{
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 23px;
    font-weight: 300;
}
.grid-boxes .inner-box h6 {
    font-size: 16px;
    margin: 20px 0;
    font-weight: 500;
    font-family: var(--font-family);
    width: 150px;
    text-align: left;
    line-height: 27px;
}

.grid-boxes .inner-box p {
    font-size: 13px;
    line-height: 1.3;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: var(--font-family);
}

.grid-boxes .inner-box .btn-proceed{
    background-color: #e75cb4;
    color: #fff;
    text-transform: uppercase;
    padding: 9px 30px;
    font-size: 13px;
    font-family: var(--font-family);
}

.grid-boxes .inner-box .btn-proceed a {
    text-decoration: none;
    color: var(--white);
}

.MuiGrid-container .grid-boxes:nth-child(n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(n+0) .top-img{
    background-color: #e75cb4;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(2n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(2n+0) .top-img{
    background-color: #8659e3;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(3n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(3n+0) .top-img{
    background-color: #3aafc6;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(4n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(4n+0) .top-img{
    background-color: #5cb55f;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(5n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(5n+0) .top-img{
    background-color: #b64f5d;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(6n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(6n+0) .top-img{
    background-color: #b2a02a;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(7n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(7n+0) .top-img{
    background-color: #ae5dc7;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.MuiGrid-container .grid-boxes:nth-child(8n+0) .inner-box .btn-proceed,
.MuiGrid-container .grid-boxes:nth-child(8n+0) .top-img{
    background-color: #787481;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

@media (max-width: 1200px) and (min-width: 600px) {
    .PopularServicesSection{
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 576px){
    .grid-boxes .inner-box h6{
        font-size: 16px;
    }

    .grid-boxes .outer-box {
        padding-top: 0px;
    }

    .grid-boxes .top-img img {
        height: 45px;
        width: 45px;
    }

    .grid-boxes .top-img {
        width: 70px;
        height: 70px;
    }
}
@media (max-width: 991px){
    .PopularServicesSection{
        height: auto;
        margin-top: 3em;
    margin-bottom: 0em;    
    padding-bottom: 1em;
    }    
}