.ResetUI{
  margin-bottom: 70px;
}
.ResetUI .heading {
  color: var(--primary);
  font-size: 22px;
  position: relative;
  width: auto;
  display: inline-block;
  font-weight: 500;
  margin: 35px auto;
}
.ResetUI .heading:before {
  position: absolute;
  content: '';
  border: 1px solid;
  width: 40px;
  left: -50px;
  top: 50%;
  border-radius: 10px;
}
.ResetUI .heading:after {
  position: absolute;
  content: '';
  border: 1px solid;
  width: 40px;
  right: -50px;
  top: 50%;
  border-radius: 10px;
}
.ResetUI .head-h2, .ResetUI .head-h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.ResetUI .head-h3 {
  color: var(--secondary);
  width: fit-content;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.ResetUI p {
  text-align: justify;
  line-height: 20px;
  font-size: 14px;
  color: #464646;
  font-weight: 500;
  margin: 28px 0;
}
.ResetUI .formSection {
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  padding: 2rem;
  text-align: left;
}
.ResetUI .formSection .head-h3 {
  margin-top: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
margin: 0; 
}
.ResetUI .formSection .btn.btn-primary {
  width: calc(50% - 20px);
  padding-top: 10px;
  margin: 2px;
}
.formSection .btn.btn-primary1 {
  width: 50%;
  padding-top: 10px;
  background-color: #daeefc;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 25px;
    text-transform: uppercase;
    font-family: var(--font-family);
    width: calc(50% - 20px);
    margin: 2px;
}
.formSection .btn.btn-primary1:hover{
  background-color:#d6e0e7 ;
}
.formSection .para{
  color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-bottom: 8px;
    font-family: var(--font-family);
}
.formSection .error-message{
  color: var(--red);
    font-size: 12px;
    margin-top: 2px;
    font-weight: 500;
}
.formSection .blue{
  color:var(--primary);
}
.formSection .MuiSelect-icon{
  fill: var(--primary);
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
}
.formSection .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent!important;
}
.date-pick svg{
  fill: var(--primary);
}
.formSection em{
  font-style: normal;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 600;
}
label .term-link{
  color: var(--secondary);
  text-decoration: none;
}

label .term-link:hover{
  text-decoration: underline;
}

.formSection.otpForm p{
  text-align: center;
}

.reset-group{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px){
  .ResetUI .heading {
      font-size: 18px;
      margin: 25px auto;
  }
  .ResetUI .heading:before,
  .ResetUI .heading:after {
     content: none;
  }
  .ResetUI .formSection{
      padding: 1rem;
  }   

  .ResetUI .head-h2, .LoginUI .head-h3{
      font-size: 15px;
  }

  .ResetUI p {
      text-align: left;
      font-size: 14px;
      margin: 17px 0;
  }
}