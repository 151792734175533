.RgisterUI {
  margin-bottom: 70px;
}
.RgisterUI .heading {
  color: var(--primary);
  font-size: 22px;
  position: relative;
  width: auto;
  display: inline-block;
  font-weight: 500;
  margin: 35px auto;
}
.RgisterUI .heading:before {
  position: absolute;
  content: "";
  border: 1px solid;
  width: 40px;
  left: -50px;
  top: 50%;
  border-radius: 10px;
}
.RgisterUI .heading:after {
  position: absolute;
  content: "";
  border: 1px solid;
  width: 40px;
  right: -50px;
  top: 50%;
  border-radius: 10px;
}
.RgisterUI .head-h2,
.RgisterUI .head-h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.RgisterUI .head-h3 {
  color: var(--secondary);
  width: fit-content;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.RgisterUI p {
  text-align: justify;
  line-height: 20px;
  font-size: 14px;
  color: #464646;
  font-weight: 500;
  margin: 28px 0;
}
.RgisterUI .formSection {
  text-align: left;
}
.RgisterUI .formSection .head-h3 {
  margin-top: 0;
}
.RgisterUI .clickText{
  color: var(--secondary);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.formSection .MuiSelect-icon {
  fill: var(--primary);
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
}
.formSection .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}
.date-pick svg {
  fill: var(--primary);
}
.formSection em {
  font-style: normal;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 600;
}
label .term-link {
  color: var(--secondary);
  text-decoration: none;
}

label .term-link:hover {
  text-decoration: underline;
}

.formSection.otpForm p {
  text-align: center;
}

.login-center-box .register-box {
  width: 100%;
}

.reg-tab-body {
  display: none;
}

.reg-tab-body.active {
  display: block;
}

.reg-tab-head {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.reg-tab-head .ttl {
  width: 33.33%;
  color: #808080;
  border-bottom: 2px solid #e7e7e7;
  padding: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.reg-tab-head .ttl.active {
  color: var(--primary);
  border-color: var(--primary);
}

.reg-tab-head .ttl .circle {
  font-size: 23px;
  margin-right: 5px;
}

.reg-tab-head .ttl .circle.done {
  color: var(--primary);
}

.formSection .btn-group .width-fit {
  width: fit-content;
  padding: 6px 30px;
}

.btn-group.flex {
  display: flex;
  align-items: center;
}

.btn-group.flex-end {
  justify-content: flex-end;
}

.btn-group.flex-space-between {
  justify-content: space-between;
}

.formSection .btn-group .btn-back {
  background: #6f6f6f;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 6px 30px;
  text-transform: capitalize;
  font-family: var(--font-family);
}

.reg-tab-body .radio-box {
  padding: 25px 40px;
  border: 2px solid #e7e7e7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.reg-tab-body .radio-box .wt,
.reg-tab-body .radio-box.active .bl,
.reg-tab-body .radio-box:hover .bl{
  display: none;
}

.reg-tab-body .radio-box.active,
.reg-tab-body .radio-box:hover {
  border-color: var(--secondary);
  color: #fff;
  background-color: var(--secondary);
}

.reg-tab-body .radio-box.active .wt,
.reg-tab-body .radio-box:hover .wt{
  display: block;
}

.reg-tab-body .radio-box img {
  height: 44px;
    margin-right: 15px;
}

.reg-tab-body .radio-group {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.reg-tab-body .radio-group > label{
  margin-left: 0;
}

.reg-tab-body .hidden {
  display: none;
}
.RgisterUI .MuiAlert-root{flex-direction: row !important;}


@media (max-width: 768px) {
  .reg-tab-head {
    flex-wrap: wrap;
  }

  .reg-tab-head .ttl {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 576px) {
  .RgisterUI .heading {
    font-size: 18px;
    margin: 25px auto;
  }
  .RgisterUI .heading:before,
  .RgisterUI .heading:after {
    content: none;
  }
  .RgisterUI .formSection {
    margin-top: 20px;
  }

  .RgisterUI .head-h2,
  .LoginUI .head-h3 {
    font-size: 15px;
  }

  .RgisterUI p {
    text-align: left;
    font-size: 14px;
    margin: 17px 0;
  }
}
