body .aside-menu{
    width: 300px;    
    font-family: var(--font-family);
    margin-right: 35px;
}
.aside-user{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aside-user .left{
    display: flex;
    align-items: center;
}

.aside-user .left .img {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

.aside-user .left .img img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}

.aside-user .left .text{
    text-align: left;
    padding-left: 7px;
}

.aside-user .left h3{
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: var(--dark);
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
}

.aside-user .left span{
    font-size: 12px;
    padding-top: 1px;
    color: var(--gray);
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
}

.aside-user .icon{
    border: 1px solid #717171;
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #e9e9e9;
    color: #717171;
    transition: all .3s ease-in;
}

.aside-user .icon svg{
    transform: rotate(90deg);
}

.aside-user .icon:hover{
    color: #fff;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
}

.aside-box{
    text-align: left;    
    font-family: var(--font-family);
}

.aside-box ul{
    list-style: none;
    padding: 0;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    overflow: hidden;
}

.aside-box ul li .ttl{
    color: var(--secondary);
    font-size: 16px;
    font-weight: 500;
    display: block;
    padding: 15px;
    padding-bottom: 5px;
}

.aside-box ul li a{
    padding: 15px;
    font-size: 13px;
    font-weight: 500;
    text-decoration: unset;
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: all .3s ease-in;
}

.aside-box ul li a.active{
    background-color: var(--primary-light);
    color: var(--primary);
}

.aside-box ul li a:hover{
    color: var(--primary);
}

.aside-box ul li a svg{
    font-size: 14px;
}

.aside-box ul li a::before{
    content: '';
    width: 95%;
    height: 0.05rem;
    background-color: #e9e9e9;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.aside-box ul li:last-child a::before{
    content: none;
}

@media (max-width: 991px){
    body .aside-menu{
        width: 100%;
        margin-right: 0;
    }
}