.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000b5;
  display: none;
  opacity: 0;
  transition: all 0.2s ease-in;
  z-index: 99;
}

.page-loading .page-loader {
  display: block;
  opacity: 1;
}

.page-loading {
  overflow: hidden;
}

.page-loader .page-loader-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.page-loader .page-loader-inner .loader-icon {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--primary);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.form-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  transition: all 0.2s ease-in;
  z-index: 10;
  display: none;
  opacity: 0;
}

.form-loading .form-loader {
  display: block;
  opacity: 1;
}

.form-loader .form-loader-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.form-loader .form-loader-inner .loader-icon {
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--secondary);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 2s linear infinite;
}


.Service-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  transition: all 0.2s ease-in;
  z-index: 10;
  display: none;
  opacity: 0;
}

.Service-loading .Service-loader {
  display: block;
  opacity: 1;
}

.Service-loader .Service-loader-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Service-loader .Service-loader-inner .loader-icon {
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--secondary);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
