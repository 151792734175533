.Services-Section {
    margin-top: 8em;
    margin-bottom: 5em;
}
.BusinessService.Services-Section
{
    margin-top: 0em;
    margin-bottom: 2em;
}
.BusinessService_box .service-page-detail{padding: 25px 0px 25px 0px;}
.Services-Section .category-outer-box {
    border: 1px solid #D3D3D3;
    border-radius: 6px;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    min-height: 73px;
    position: relative;
}

.Services-Section .category-outer-box .info-icon{
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 19px;
    color: #808080;
}

.Services-Section .category-outer-box .info-icon:hover{
    color: var(--secondary);
}

.left-img {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 50%;
    height: 60px;
    background-color: #ababab;
    padding: 12px;
    box-sizing: border-box;
}
.left-img img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.Services-Section  .MuiGrid-item:nth-child(n + 0) .left-img{
    background-color: #E69F23;
}
.Services-Section  .MuiGrid-item:nth-child(2n + 0) .left-img{
    background-color: #0DA1B8;
}
.Services-Section  .MuiGrid-item:nth-child(10n + 0) .left-img{
    background-color: #F96B53;
}
.Services-Section  .MuiGrid-item:nth-child(4n + 0) .left-img{
    background-color: #21C9A5;
}
.Services-Section  .MuiGrid-item:nth-child(5n + 0) .left-img{
    background-color: #4471F9;
}
.Services-Section  .MuiGrid-item:nth-child(8n + 0) .left-img{
    background-color: #AD5CC7;
}
.right-cont {
    width: 77%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    line-height: 21px;
}
.right-cont h4, .right-cont p{margin:  0;font-family: var(--font-family);    letter-spacing: 0.5px;}
.right-cont p {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: var(--font-family);
}
.right-cont h4{
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-family: var(--font-family);
}
.view-btn {
    border: 1px solid #CE1126;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
}
.css-mhc70k-MuiGrid-root{display: flex;align-items: center;}
.view-btn span {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #CE1126;
    font-family: var(--font-family);
    font-weight: 600;
}
.flex-view-btn{
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 10px;
}
.Services-Section .sec-head h2{margin-bottom: 15px;}
.Services-Section a{text-decoration: none;    color: inherit;}

.notify-popup .notify-proceed {
    outline: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary);
    color: #fff;
    border: none;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-left: 10px;
    text-decoration: unset;
}

.notify-popup .notify-proceed:hover{
    background-color: var(--primary);
    color: #fff;
}

@media (max-width: 1200px) and (min-width: 600px) {
    .Services-Section{
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 991px) {
    .view-btn{margin-bottom: 10px;}
}
@media (max-width: 768px){    
    .Services-Section {
        margin-top: 3em;
        margin-bottom: 2em;
    }
    .view-btn{margin-bottom: 10px;    margin-right: 18px;}
}

@media (max-width: 567px){
    .right-cont h4{
        overflow: unset;
        text-overflow: unset;
        display: block;
    }
}