.form-section2 .vax-information{
    background-color:#ffff ;
    padding-top: 4px;
    border-radius: 3px
}
/* CSS to display checkboxes in a row */
.form-section2 .list {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding-left: 0px;
}

.form-section2 .list li {
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: #f3f5f8;
  border-radius: 31px;
  padding: 5px;
  border:1px solid #ddd6d6;
}
.form-section2 .list li:hover{
  border: 1px solid#7f9cb9;
}
.form-section2  .vax-information h2{
color:#000;
font-size: 13px;
padding: 10px 10px;
text-align: left;
border-left: 2px solid#636fb1;
margin: 4px 0px;
background-color: #b5cce436;

}
.form-section2 .vax-information .p-tag{
color:#215487;
font-size: 14px;
font-weight: 600;
letter-spacing: -0.2px;
margin-bottom: 8px;
text-align: left;
font-family: var(--font-family);
display:flex;
}
.form-section2  .box-content2{

  padding: 8px 15px;
}
.form-section2  .box-content3{

 padding: 2px 15px 150px 15px;
}

.form-section2 .errorMsg {
color: var(--red);
font-size: 12px;
margin-top: 2px;
font-weight: 500;
text-align: left;
}
.form-section2 .form-sec{
display: flex;
align-items: center;
justify-content: space-between;

}
.form-section2 input.round-checkbox{
font-style: normal;
font-size: 12px;
font-family: var(--font-family);
font-weight: 400;
border-radius: 50%;
}

.form-section2 .submit{
display: flex;
align-items: center;
justify-content: flex-end;

}
.form-section2 .btn{
background-color:#ce1126;
color: #fff;
font-family: var(--font-family);
font-weight: 600;
float: right;
margin: 15px 0px;
padding: 0.5rem 1rem;
font-size: 13px;
}
.form-section2 .btn1{
background-color:#fff;
color:#000;
font-family: var(--font-family);
font-weight: 600;
margin: 15px 7px;
padding: 0.5rem 1rem;
font-size: 13px;
border: 1px solid #ddd;

}
.form-section2 .list li.checked {
  border: 1px solid #7f9cb9;
}

.form-section2 .btn:hover{
color: #fff;
background-color:#ee5668 ;
}

.form-section2 .btn .right{
width: 20px;
height: 20px;
}
.form-section2 .next{
display: flex;
align-items: center;
font-weight: 600;
font-family: var(--font-family);
}
.form-section2 .previous{
display: flex;
align-items: center;
font-weight: 600;
font-family: var(--font-family);
font-size: 13px;
}


.form-section2 .submit{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    }
    .form-section2 .btn{
    background-color:#ce1126;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 600;
    float: right;
    margin: 15px 0px;
    padding: 0.5rem 1rem;
    font-size: 13px;
    }
    .form-section2 .btn1{
    background-color:#fff;
    color:#000;
    font-family: var(--font-family);
    font-weight: 600;
    margin: 15px 7px;
    padding: 0.5rem 1rem;
    font-size: 13px;
    border: 1px solid #ddd;
    
    }
    .form-section2 .btn:hover{
    color: #fff;
    background-color:#ee5668 ;
    }
    
    .form-section2 .btn .right{
    width: 20px;
    height: 20px;
    }
    .form-section2 .next{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: var(--font-family);
    }
    .form-section2 .previous{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: var(--font-family);
    font-size: 13px;
    }

.form-section .css-19kzrtu {
  padding: 0px;
}
.form-section2 .css-1oqqzyl-MuiContainer-root {
  padding-left: 0px; 
   padding-right: 0px; 
}
.form-section2 .box-border{
  border: 1px solid #ddd;
 padding-bottom: 1rem;
  border-radius: 7px;
}
/* vax.css */
.vax-information .round-checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 4px;
  }
  .vax-information .round-checkbox-label-1 {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 4px;
   
  }
  .vax-information .container {
    display: block;
    position: relative;
  padding-left: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
    cursor: pointer;
    font-size: 12px;
    font-family: var(--font-family);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
 .vax-information .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .vax-information .checkmark {
    position: absolute;
    top: 4px;
    left: 7px;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 15px;
   
  }
  
  /* On mouse-over, add a grey background color */
  .vax-information .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .vax-information .container input:checked ~ .checkmark {
    background-color: #0b437b;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .vax-information .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .vax-information .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .vax-information .container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    overflow: unset!important;
    border-radius: 15px;
   
  }
  .dialog-content{
   padding: 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color:#f7f7f7;
    border-radius: 15px;
    width: 33rem;
    /* height: 15rem; */
}
.dialog-content .tick{
  /* left: 6px; */
  position:absolute;
  /* top: 4px; */
}
  .dialog-content .bor{
    border-radius: 50%;
    height: 85px;
    width: 85px;
    border: 1px solid #ddd;
    background-color: #fff;
    position: absolute;
    top: -45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialog-content h4{
    color: #4bae4f;
    font-family: var(--font-family);
    font-size: 20px;
    letter-spacing: 0.3px;
    font-weight: 500;
    padding-top: 1.5rem;
    margin-bottom: 1.2rem;  
  }
  .dialog-content .qr-code{
    height: 70px;
    width: 70px;
    margin-bottom: 0.3rem;
  }
  .dialog-content button{
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    font-family: var(--font-family);
    border-radius: 5px;
    padding: 11px 14px;
    letter-spacing: 0.5px;
    border: 0;
  }
  .dialog-content p{
    margin: 2px;
    font-size: 13px;
    font-family: var(--font-family);
    text-align: justify;
    letter-spacing: 0.3px;
    color: #000;
    font-weight: 400;
    line-height: 20px;
  }
  .dialog-content .ref{
    color: #0b437b;
    font-weight: 600;
  }
  .dialog-content .white{
    background-color: #fff;
    margin: 20px 0px;
    text-align-last: center;
    width: 100%;
    padding: 10px;
  }
  .dialog-content .down-img{
    height: 19px;
    width: 19px;
  }