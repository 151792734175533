.error-section {
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.error-section .error-icon{
    font-size: 50px;
    color: var(--secondary);
}

.error-section h2{
    font-size: 40px;
    margin: 10px 0;
    color: var(--secondary);
    margin-top: 0;
}

.error-section .btn-reload,
.error-section .btn-reload:hover{
    outline: none;
    padding: 7px 20px;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--secondary);
    color: #fff;
    border: none;
    font-size: 14px;
    position: relative;
    text-transform: none;
}

.error-section p{
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 30px;
}

@media (max-width: 587px) {
    .error-section h2 {
        font-size: 30px;
    }
}