.LoginUI {
  margin-bottom: 25px;
}
.LoginUI .heading,
.head-with-line {
  color: var(--primary);
  font-size: 22px;
  position: relative;
  width: auto;
  display: inline-block;
  font-weight: 500;
  margin: 35px auto;
}
.LoginUI .heading:before,
.head-with-line::before {
  position: absolute;
  content: "";
  border: 1px solid;
  width: 40px;
  left: -50px;
  top: 50%;
  border-radius: 10px;
}
.LoginUI .heading:after,
.head-with-line::after {
  position: absolute;
  content: "";
  border: 1px solid;
  width: 40px;
  right: -50px;
  top: 50%;
  border-radius: 10px;
}
.LoginUI .head-h2,
.LoginUI .head-h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.LoginUI .head-h3 {
  color: var(--secondary);
  width: fit-content;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.LoginUI p {
  text-align: justify;
  line-height: 20px;
  font-size: 14px;
  color: #464646;
  font-weight: 500;
  margin: 28px 0;
}
.LoginUI .formSection {
  text-align: left;
}
.LoginUI .formSection .head-h3 {
  margin-top: 0;
}
.LoginUI .MuiPaper-root{flex-direction: row;}

.devider {
  position: relative;
  margin: 50px 0;
}

.devider hr {
  border: 1.5px solid var(--primary);
}

.devider span {
  color: var(--primary);
  border: 2px solid var(--primary);
  background-color: #fff;
  border-radius: 50%;
  padding: 15px;
  font-size: 13px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}

.outer-common-ui .outer-header {
  background: transparent
    linear-gradient(98deg, #000000 0%, #1d0002 50%, #420007ed 100%) 0% 0%
    no-repeat padding-box;
  padding: 20px 0;
  padding-bottom: 100px;
}

.outer-common-ui .outer-header .outer-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.outer-header .left-div h6 {
  text-align: left;
  font: normal normal normal 20px/27px Noto Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 18px;
  margin: 0;
}

.outer-common-ui .outer-inner {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: -80px;
  padding: 35px;
  box-sizing: border-box;
  margin-bottom: 25px;
}

body .outer-inner .formSection{
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.login-center-box {
  padding: 50px 0;
  background: transparent
  linear-gradient(98deg, #000000 0%, #1d0002 50%, #420007ed 100%) 0% 0%
  no-repeat padding-box;
  position: relative;
}

.login-center-box::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../../../assets/Img/bannerscheme.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.19;
}

.login-center-box .login-box{
  width: 450px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 576px) {
  .LoginUI .heading,
  .head-with-line {
    font-size: 18px;
    margin: 25px auto;
  }
  .LoginUI .heading:before,
  .head-with-line::before,
  .LoginUI .heading:after,
  .head-with-line::after {
    content: none;
  }

  .LoginUI .head-h2,
  .LoginUI .head-h3 {
    font-size: 15px;
  }

  .LoginUI p {
    text-align: left;
    font-size: 14px;
    margin: 17px 0;
  }

  .outer-common-ui .outer-inner{
    padding: 18px;
  }

  body .outer-inner .formSection{
    padding: 0;
    margin-top: 15px;
  }

  .login-center-box {
    padding: 16px 0;
  }
  
  .login-center-box .login-box{
    width: 100%;
  }
}
